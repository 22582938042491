
export default {
  ChatSelect({
    commit
  }, val) {
    commit("setChatSelected", val);
  },
  DiscoverSelect({
    commit
  }, val) {
    commit("setDiscoverSelected", val);
  },

}