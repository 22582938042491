export function langChange(langCode) {
        // console.log(langCode);
        if(langCode.includes("en")) {
            return "en-US";
        } else if (langCode.includes("TW") || langCode.includes("HK")) {
            return "zh-HK";
        } else if (langCode.includes("zh")) {
            return "zh-CN";
        } else if (langCode.includes("ar")) {
            return "ar-AE";
        } else if (langCode.includes("pl")) {
            return "pl-PL";
        } else if (langCode.includes("fa")) {
            return "fa-IR";
        } else if (langCode.includes("da")) {
            return "da-DK";
        } else if (langCode.includes("de")) {
            return "de-DE";
        } else if (langCode.includes("ru")) {
            return "ru-RU";
        } else if (langCode.includes("fr")) {
            return "fr-FR";
        } else if (langCode.includes("nl")) {
            return "nl-NL";
        } else if (langCode.includes("cs")) {
            return "cs-CZ";
        } else if (langCode.includes("ro")) {
            return "ro-RO";
        } else if (langCode.includes("pt")) {
            return "pt-BR";
        } else if (langCode.includes("ja")) {
            return "ja-JP";
        } else if (langCode.includes("th")) {
            return "th-TH";
        } else if (langCode.includes("tr")) {
            return "tr-TR";
        } else if (langCode.includes("ca")) {
            return "ca-ES";
        } else if (langCode.includes("el")) {
            return "el-GR";
        } else if (langCode.includes("it")) {
            return "it-IT";
        } else if (langCode.includes("vi")) {
            return "vi-VN";
        } else if (langCode.includes("hi")) {
            return "hi-IN";
        }
        return "en-US";

}