export default {

    // 迪沃 正式环境  official文件夹

    // 手表IMEI 接口
    watchList: [],
    totalNumber: 0,


    os: '',
    osVersion: '',
    deviceId: '',
    appPkgName: '',
    appVersion: '',
    sdkVersion: '',
    channelId: '',
    sdkChannelId: '',
    token: '',
    bind: '',
    notificationId: '',
    notificationType: '',
    language: '',
    watch: [],
    watchId: '',
    

    pP:"PuRhwU",
    pOs: "OM",
    pOsVersion: 'OMvlBUcAZ',
    pDeviceId:"DyVpmGCp",
    pAppPkgName:"AJPWuIHmYi",
    pAppVersion:"AJPcoTmuar",
    pSdkVersion:"SxKcoTmuar",
    pChannelId:"CBAuxGfUP",
    pSdkChannelId:"SxKJrChzQpix",
    pToken:"TIKlx",
    pBind: "BCNk",
    pNotificationId : "NITppKWmfmOHik",
    pNotificationType: "NITppKWmfmOHtFzG",
    pLanguage: "LuNnECaq",
    pWatch: "WuTjr",
    pWatchId: "WuTjrkX",
    

    obj:"oMjzLTcpSi",
    type: "TSPl",
    subType:"SOBaIRY",
    msg:"MMG",

    audio:"AODpy",
    image:"IGAno",
    lifeCycle:"LCFlMaWxQ",


    // 关闭音频新增映射
    backOut:"BuCrYWn",
    backStage:"BuCrcVUsQ",

    permission:"PyRtsUmuar",
    saveImage:"SuVlSOUsQ",
    startRecordAudio:"SNAyDtYoavDUUksQ",
    cancelRecordAudio:"CuNjoNLqOsRxaBnKi",
    finishRecordAudio:"FCNpCJLqOsRxaBnKi",

    mode: "MIDl",
    content: "CINAoPn",
    
    osResult: "OMrlCWfF",

  tabBarName: "Chat",
  ChatSelect: 1,
  DiscoverSelect: 1,
  watchdate: null,
  fontSize:'4vw',
  showSound:false,//声音开关
  isInit:false,//是否初始化
  myCreation:[],//我创建的智能体
  subscription:1,
}