module.exports = {
	language: "意大利语",

	navigator: {
		message1: "Chat",
		message2: "Intelligenza Artificiale",
		message3: "Mio",
	},
	chat: {
		adjustment: {
			message1: "Regolazione",
			message2: "Aiutami a visualizzare l'anteprima della dimensione del carattere",
			message3: "Puoi regolare la dimensione del carattere trascinando il cursore. Una volta impostato, la dimensione del carattere nella conversazione cambierà.",

		},
		aiFriendPages: {
			message1: "Ciao, sono il tuo nuovo amico! Piacere di conoscerti per la prima volta. Posso rispondere a tutte le tue domande, aiutarti con il lavoro e lo studio, e chiacchierare con te in qualsiasi momento. Bene, cosa vuoi chiedere per primo?",
			message2: "Domanda",
			message3: "Poiché il contenuto è generato da un grande modello, ",
			message4: "l'autenticità non può essere completamente garantita.",
			message5: "Ufficiale",
		},
		downloadImg: {
			message1: "Scarica",
			message2: "Salvato con successo nell'album",
			message3: "",
			message4: "",
			message5: "",
		},
		searchPage: {
			message1: "CARICAMENTO",
			message2: "Nessun risultato di ricerca disponibile al momento",
			message3: "",
			message4: "",
			message5: "",
		},
		smartAiInfo: {
			message1: "Regolazione dimensione carattere",
			message2: "Cancella cronologia chat",
			message3: "Se cancellare l'attuale registro chat",
			message4: "Annulla",
			message5: "Conferma",
		},
		smartAiPages: {
			message1: "immagine in fase di generazione",
			message2: "Clicca ovunque o quando termina il conto alla rovescia per identificare",
			message3: "Clicca per parlare",
			message4: "Il contenuto è generato dall'IA ed è solo a scopo di riferimento.",
			message5: "L'agente intelligente è stato eliminato",
		},
		smartAiPagesDEV: {
			message1: "immagine in fase di generazione",
			message2: "Clicca ovunque o quando termina il conto alla rovescia per identificare",
			message3: "Clicca per parlare",
			message4: "Il contenuto è generato dall'IA ed è solo a scopo di riferimento.",
			message5: "L'agente intelligente è stato eliminato",
			message6:"Questa Smart AI non supporta l'invio di file e immagini",
			message7:"Questa Smart AI non supporta l'invio di file",
			message8:"Conversazione corrente in corso",
			message9:"Le parole di richiesta di disegno dell'AI violano le normative, inserisci parole di richiesta di disegno conformi.",
			message10:"Questa immagine viola le normative e non può essere riconosciuta.",
			message11:"Questa conversazione ha raggiunto il limite, per favore inizia una nuova sessione di chat Al ",
			message12:"Nuovo dialogo",
			message13:"Tutti i server sono occupati. Riprova. Questo non consumerà i tuoi crediti.",
			message14:"Il numero di domande e risposte è stato esaurito. Vai all'orologio e scansiona il codice QR per continuare a utilizzarlo.",
		},
		smartAiReadonly: {
			message1: "Ufficiale",
			message2: "Ciao, sono il tuo nuovo amico! Piacere di conoscerti per la prima volta. Posso rispondere a tutte le tue domande, aiutarti con il lavoro e lo studio, e chiacchierare con te in qualsiasi momento. Bene, cosa vuoi chiedere per primo?",
			message3: "Puoi regolare la dimensione del carattere trascinando il cursore. Una volta impostato, la dimensione del carattere nella conversazione cambierà.",
			message4: "Ciao, sono il tuo nuovo amico! Piacere di conoscerti per la prima volta. Posso rispondere a tutte le tue domande, aiutarti con il lavoro e lo studio, e chiacchierare con te in qualsiasi momento. Bene, cosa vuoi chiedere per primo?",
			message5: "",
		},
		watchPages: {
			message1: "Guarda1",
			message2: "Ufficiale",
			message3: "Sono qui. Come posso aiutarti",
			message4: "Elimina Intelligenza Artificiale",
			message5: "Elimina cronologia chat",
		},
		index: {
			message1: ["Consigliare", "Studio", "Vita", "Creazione", "Pittura", "Lavoro"],
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	components: {
		AIFriend: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		chatAI: {
			message1: "Non hai ancora creato nessuna conversazione",
			message2: "Guarda 0",
			message3: "Annulla Preferiti",
			message4: "Confermi l'aggiunta dell'IA intelligente a watchName?",
			message5: "Annulla",
			message6: "Conferma",
		},
		MyAI: {
			message1: "Mi dispiace. Non hai ancora aggiunto nulla ai preferiti",
			message2: "Elimina",
			message3: "Annulla Preferiti",
			message4: "Confermi l'aggiunta di AI Art a questo watch?",
			message5: "elimina Al Botanist",
			message6: "Annulla",
			message7: "Conferma",
		},
		SmartAI: {
			message1: "Mi dispiace. Non hai ancora aggiunto nulla ai preferiti",
			message2: "Elimina",
			message3: "Annulla Preferiti",
			message4: "Confermi l'aggiunta dell'IA intelligente a ",
			message5: "Elimina l'IA intelligente",
			message6: "Annulla",
			message7: "Conferma",
		},
		SmartAI2: {
			message1: "Mi dispiace. Non hai ancora aggiunto nulla ai preferiti",
			message2: "Elimina SmartAI",
			message3: "Annulla Preferiti",
			message4: "Confermi l'aggiunta dell'IA intelligente a ",
			message5: "Elimina l'IA intelligente",
			message6: "Annulla",
			message7: "Conferma",
		},
		Watch: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	//做到这里
	create: {
		addSA: {
			message1: "Crea",
			message2: "Aggiungi Avatar",
			message3: "Nome",
			message4: "Imposta Descrizione",
			message5: "Lingua",
			message6: "Suono",
			message7: "Crea",
			message8: "Generazione AI",
			message9: "Caricamento Album",
			message10: "Annulla",
			message11: "Esempio: Sei un insegnante di inglese esperto con un metodo che ispira gli studenti a imparare. Usi l'umorismo e esempi di vita reale per rendere le conversazioni interessanti.",
			message12: "Questa immagine sarà utilizzata per gli avatar AI",
		},
		aiGeneration: {
			message1: "Generazione AI",
			message2: "Aggiungi un'immagine di riferimento (opzionale)",
			message3: "Carica una foto con un viso chiaro per aiutare a generare l'immagine",
			message4: "nome",
			message5: "Crea",
			message6: "Aggiungi descrizione immagine",
		},
		createSA: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		selectLanguage: {
			message1: "Lingua",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		selectSound: {
			message1: "Suono",
			message2: "Nessun suono attualmente selezionato",
			message3: "Gentle Peach",
			message4: "Elimina SmartAI",
			message5: "",
		},
		index: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	devePage: {

	},
	discover: {

	},
	support: {
		message1: "Smart AI",
		message2: "La Mia Creazione",
		message3: "Aprire",
		message4: "Tutti possono parlare",
		message5: "Privato",
		message6: "Solo tu puoi parlare",
		message7: "Inserisci un nome",
		message8: "Cerca AI",
		message9: "Mandare un messaggio ",
		message10: "Standard",
	},
	mine: {
		mineWatchPages: {
			message1: "Elimina SmartAI",
			message2: "Sei sicuro di voler eliminare SmartAI dal tuo watch?",
			message3: "Annulla",
			message4: "La home page è piena",
			message5: "La tua home page del watch ha raggiunto il limite di Smart AI. Rimuovi alcune Smart AI prima di aggiungerne di nuove.",
			message6: "Modifica Nome",
			message7: "Conferma",
			message8: "Il Mio Watch",
			message9: "iMei",
			message10: "Watch Smart AI",
			message11: "Impostazioni Watch",
			message12: "Aggiungi alla Home Page del Watch",
			message13: "Classificazione contenuto Al",
			message14: "Rimuovi dalla home page dell'orologio",
		},
		settingindex: {
			message1: "Configura",
			message2: "Modalità AI",
			message3: "Lingua",
			message4: "Registro Ordini",
			message5: "Feedback del Problema",
			message6: "Avviso",
			message7: "Termini di Servizio",
			message8: "Politica sulla Privacy",
			message9: "Apertura Fallita",
			message10: "Il registro ordini non può essere aperto. Si prega di scansionare il codice QR sull'orologio per aprirlo.",
			message11: "Conferma",
		},
		Language: {
			message1: "Lingua",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		MySubscription: {
			message1: "Modalità Selezione Smart AI",
			message2: "Pro",
			message3: "Standard",
			message4: "",
			message5: "",
		},
		index: {
			message1: "Il Mio Watch",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	points: {},
	smartai: {
		collectFriendlyAI: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collectInspiration: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collectSmartAi: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collect: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		discoverAF: {
			message1: ["Il Mio", "In Primo Piano", "Femminile", "Maschile", "Anime", "Gioco"],
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		discoverSA: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		inspiration: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		mycreation: {
			message1: "Crea la Mia Smart AI",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	Toast: {
		message1: "Jarvis",
		message2: "Copia avvenuta con successo",
		message3: "Questa Intelligenza Artificiale non supporta l'invio di file e immagini",
		message4: "La conversazione corrente è in corso",
		message5: "Il nome deve avere almeno 4 caratteri",
		message6: "Il contenuto deve avere almeno 4 caratteri",
		message7: "successo",
		message8: "errore",
		message9: "nome",
		message10: "prompt",
		message11: "privacy",
		message12: "lingua",
		message13: "codice suono AI",
		message14: "immagine",
		message15: "nullo",
		message16: "Spostamento AI Intelligente",
		message17: "Aggiunto con successo",
		message18: "Rimosso con successo",
		message19: "Aggiunta Intelligenza Artificiale fallita",
		message20: "Connessione Internet instabile. Per favore riprova.",
		message21: "Aggiunta Intelligenza Artificiale Completata",
		message22: "Eliminazione Intelligenza Artificiale fallita",
		message23: "Connessione Internet instabile. Per favore aggiungi di nuovo l'Intelligenza Artificiale.",
		message24: "elimina Al Botanist",
		message25: "Eliminazione Intelligenza Artificiale Completata",
		message26: "Eliminazione Intelligenza Artificiale fallita Connessione Internet instabile. Per favore aggiungi di nuovo l'Intelligenza Artificiale",
		message27: "Questa Intelligenza Artificiale non supporta l'invio di file e",
		message27: "Copia fallita, per favore seleziona manualmente copia!",
		message28: "Creazione in corso",
		message29: "valore nullo",
		message30: "Eliminazione Intelligenza Artificiale in corso",
		message31: "Aggiunta Intelligenza Artificiale in corso",
		message32: "Eliminazione Intelligenza Artificiale in corso",
		message33: "Il numero di domande e risposte generali è esaurito.  ",
		message34: "Il numero di sessioni avanzate di domande e risposte è stato esaurito.",
	},
	setting: {
		index: {
			title: "Setting",
			problem: "Feedback del problema",
			order: "Registrazione dell'ordine",
			notice: "Notifica",
			language: "Lingua",
			clear: "Cancella Cache",
			clearTip: "Vuoi confermare \n la cancellazione della cache?",
			confirm: "Conferma",
			cancel: "Annulla",
		},
		language: {
			title: "lingua",
			langList: ["Cinese semplificato", "cinese tradizionale", "Inglese", "Arabo", "Polacco", "persiano",
				"danese", "Tedesco", "russo", "francese", "Olandese", "ceco", "rumeno", "portoghese",
				"giapponese", "tailandese", "Turco", "spagnolo", "greco", "Italiano", "vietnamita", "hindi"
			],
		},
		order: {
			title: "Registrazione dell'ordine",
			success: "Ottenuto con successo",
			unpaid: "Non pagato",
			paid: "Pagato",
			expired: "Scaduto",
			refunded: "Rimborsato",
			pTime: "Orario del pagamento",
			eTime: "Orario di scadenza",
			pMethod: "Metodo di pagamento",
			pObject: "Oggetto del pagamento",
			oNumber: "Numero dell'ordine",
			pType: "Tipo di pacchetto",
			oTime: "Orario di ottenimento",
			watch: "Guarda IMEI",
			delete: "Elimina ordine",
			refund: "Richiedi rimborso",
		},
		problem: {
			title: "Feedback sui problemi",
			feedback: "Tipo di feedback",
			please: "Per favore scegli",
			content: "Contenuto",
			improve: "Per favore scrivi i tuoi suggerimenti, come requisiti funzionali, reclami sul prodotto, ecc. Lavoreremo sodo per migliorare",
			upload: "Carica immagine",
			submit: "Invia",
			general: "Feedback Generale",
			purchase: "Relativo all'acquisto",
			bug: "Segnalazione Bug",
			suggestion: "Suggerimento",
			other: "Altro",
		},
		notice: {
			title: "Avviso",
			type: "Tipo di notifica: ",
			time: "Ora della notifica: "
		}
	},
	policy: {
		title: {
			terms: "Termini",
			about: "Chi siamo",
			service: "Termini di servizio",
			privacy: "Politica sulla Privacy",
			refund: "Politica di rimborso",
			us: "Chi siamo",
		},
		service: {
			section1: `<p>Il sito web situato all'indirizzo https://aiwatchstore.org/ è un'opera protetta da copyright appartenente a HONG KONG ADFLASH MEDIA LIMITED. Alcune funzionalità del sito possono essere soggette a linee guida, termini o regole aggiuntive, che saranno pubblicate sul sito in relazione a tali funzionalità.</p>
<p>Tutti questi termini, linee guida e regole aggiuntive sono incorporati per riferimento in questi Termini.</p>
<p>Questi Termini di utilizzo descrivono i termini e le condizioni legalmente vincolanti che regolano l'uso del sito. ACCEDENDO AL SITO, STAI ACCETTANDO QUESTI TERMINI e dichiari di avere l'autorità e la capacità di accettarli. DOVRESTI AVERE ALMENO 18 ANNI DI ETÀ PER ACCEDERE AL SITO. SE NON SEI D'ACCORDO CON TUTTE LE DISPOSIZIONI DI QUESTI TERMINI, NON ACCEDERE E/O UTILIZZARE IL SITO.</p>
<p>Questi termini richiedono l'uso della sezione 10.2 sull'arbitrato su base individuale per risolvere le controversie e limitano anche i rimedi disponibili per te in caso di disputa.</p>
<h2>Accesso al Sito</h2>
<p><strong>Soggetto a questi Termini.</strong> We ti concede una licenza non trasferibile, non esclusiva, revocabile e limitata per accedere al sito esclusivamente per il tuo uso personale e non commerciale.</p>
<p>We si riserva il diritto di modificare, sospendere o cessare il sito con o senza preavviso. Confermi che We non sarà ritenuto responsabile nei tuoi confronti o nei confronti di terzi per qualsiasi modifica, interruzione o cessazione del sito o di qualsiasi parte di esso.</p>
<p><strong>Nessun supporto o manutenzione.</strong> Accetti che we non avrà alcun obbligo di fornirti alcun supporto in relazione al sito.</p>
<p>Escludendo qualsiasi contenuto dell'utente che puoi fornire, sei consapevole che tutti i diritti di proprietà intellettuale, inclusi i diritti d'autore, i brevetti, i marchi e i segreti commerciali, del sito e dei suoi contenuti sono di proprietà di We o dei suoi fornitori. Si noti che questi Termini e l'accesso al sito non ti concedono alcun diritto, titolo o interesse in o su qualsiasi diritto di proprietà intellettuale, ad eccezione dei diritti di accesso limitati espressi nella sezione 2.1. We e i suoi fornitori si riservano tutti i diritti non concessi in questi Termini.</p>`,
			section2: `<h2>Termini di Abbonamento</h2>
<p>Benvenuto nel nostro servizio di abbonamento (di seguito denominato il "Servizio"). Questo servizio è fornito da HONG KONG ADFLASH MEDIA LIMITED (di seguito denominato "noi" o "la Società"). Utilizzando o abbonandoti al nostro servizio, accetti di rispettare questi Termini di Abbonamento (di seguito denominati i "Termini").</p>
<p>Noi ti forniremo servizi basati su abbonamento, inclusi contingenti esclusivi per gli utenti. Le specifiche offerte di servizio e i costi possono cambiare in qualsiasi momento e saranno aggiornati tramite notifiche via email o sul nostro sito web.</p>
<p>Tariffe di abbonamento, metodi di pagamento e cicli di pagamento sono dettagliati nella pagina dei prezzi. Gli account con pagamenti in arretrato avranno i loro servizi a pagamento temporaneamente sospesi.</p>
<p>Tutte le tariffe di abbonamento non sono rimborsabili, salvo quanto richiesto dalla legge o a nostra esclusiva discrezione.</p>
<p>Puoi cancellare il tuo abbonamento in qualsiasi momento. Al momento della cancellazione, continueremo a fornire servizi fino alla fine del periodo di abbonamento corrente, ma non verranno emessi rimborsi per le tariffe già pagate.</p>
<p>Ci riserviamo anche il diritto di terminare o sospendere unilateralmente il tuo servizio nelle seguenti circostanze:</p>
<p>- Violazione di questi Termini</p>
<p>- Mancato pagamento delle tariffe di abbonamento</p>
<p>Faremo ogni sforzo per fornire servizi di alta qualità, ma non forniamo alcuna garanzia espressa o implicita riguardante la disponibilità, l'accuratezza o la completezza del servizio.</p>`,
			section3: `<h2>Altri Utenti</h2>
<p><strong>Altri Utenti.</strong> Ogni utente del sito è l'unico responsabile di qualsiasi contenuto dell'utente proprio. Poiché non controlliamo il contenuto dell'utente, riconosci e accetti che non siamo responsabili di alcun contenuto dell'utente, sia fornito da te che da altri. Accetti che noi non saremo responsabili di alcuna perdita o danno derivante da tali interazioni. Se c'è una disputa tra te e qualsiasi utente del sito, non siamo obbligati a intervenire.</p>
<p>Con la presente, rilasci e per sempre esoneri noi e i nostri funzionari, dipendenti, agenti, successori e cessionari da, e con la presente rinunci e abbandoni, ogni e qualsiasi disputa, reclamo, controversia, domanda, diritto, obbligo, responsabilità, azione e causa di azione di ogni tipo e natura, che è sorta o sorge direttamente o indirettamente da, o che si riferisce direttamente o indirettamente al sito. Se sei un residente della California, con la presente rinunci alla sezione 1542 del codice civile della California in relazione a quanto sopra, che afferma: "una liberatoria generale non si estende ai reclami che il creditore non conosce o sospetta esistano a suo favore al momento dell'esecuzione della liberatoria, che se conosciuti da lui o lei devono aver influito materialmente sulla sua regolazione con il debitore."</p>
<p><strong>Cookies e Web Beacons.</strong> Come qualsiasi altro sito web, utilizziamo i 'cookie'. Questi cookie sono utilizzati per memorizzare informazioni tra cui le preferenze dei visitatori e le pagine del sito web che il visitatore ha visitato. Le informazioni sono utilizzate per ottimizzare l'esperienza degli utenti personalizzando il contenuto delle nostre pagine web in base al tipo di browser dei visitatori e/o altre informazioni.</p>
<h2>Clausole di esclusione della responsabilità</h2>
<p>Il sito è fornito su base "così com'è" e "come disponibile", e noi e i nostri fornitori escludiamo espressamente ogni e qualsiasi garanzia e condizione di qualsiasi tipo, sia espressa, implicita o statutaria, incluse tutte le garanzie o condizioni di commerciabilità, idoneità per uno scopo particolare, titolo, quieto godimento, accuratezza o non violazione. Noi e i nostri fornitori non garantiamo che il sito soddisferà le tue esigenze, sarà disponibile in modo ininterrotto, tempestivo, sicuro o privo di errori, o sarà accurato, affidabile, privo di virus o altri codici dannosi, completo, legale o sicuro. Se la legge applicabile richiede delle garanzie riguardanti il sito, tutte tali garanzie sono limitate a una durata di novanta (90) giorni dalla data del primo utilizzo.</p>
<p>Alcune giurisdizioni non consentono l'esclusione di garanzie implicite, quindi l'esclusione sopra indicata potrebbe non essere applicabile a te. Alcune giurisdizioni non consentono limitazioni sulla durata di una garanzia implicita, quindi la limitazione sopra indicata potrebbe non essere applicabile a te.</p>`,
			section4: `<h2>Limitazione della Responsabilità</h2>
<p>Nella massima misura consentita dalla legge, in nessun caso noi o i nostri fornitori saremo responsabili nei tuoi confronti o nei confronti di terzi per qualsiasi perdita di profitti, perdita di dati, costi di acquisto di prodotti sostitutivi, o per qualsiasi danno indiretto, consequenziale, esemplare, incidentale, speciale o punitivo derivante da o correlato a questi termini o al tuo uso del sito o alla tua incapacità di usarlo, anche se siamo stati avvisati della possibilità di tali danni. L'accesso e l'uso del sito sono a tua esclusiva discrezione e rischio, e sarai l'unico responsabile per qualsiasi danno al tuo dispositivo o sistema informatico, o perdita di dati risultante da ciò.</p>
<p>Nella massima misura consentita dalla legge, indipendentemente da qualsiasi disposizione contraria contenuta in questo documento, la nostra responsabilità nei tuoi confronti per qualsiasi danno derivante da o correlato a questo accordo sarà sempre limitata a un massimo di cinquanta dollari statunitensi (50 $ USA). L'esistenza di più di un reclamo non aumenterà questo limite. Accetti che i nostri fornitori non avranno alcuna responsabilità di alcun tipo derivante da o correlata a questo accordo.</p>
<p>Alcune giurisdizioni non consentono la limitazione o l'esclusione di responsabilità per danni incidentali o consequenziali, quindi la limitazione o l'esclusione sopra indicata potrebbe non essere applicabile a te.</p>
<p><strong>Termine e Risoluzione.</strong> Soggetti a questa sezione, questi termini rimarranno in pieno vigore ed effetto mentre usi il sito. Noi possiamo sospendere o terminare i tuoi diritti di usare il sito in qualsiasi momento per qualsiasi motivo a nostra esclusiva discrezione, incluso per qualsiasi uso del sito in violazione di questi termini. Alla risoluzione dei tuoi diritti ai sensi di questi termini, il tuo account e il diritto di accedere e usare il sito termineranno immediatamente. Comprendi che qualsiasi risoluzione del tuo account può comportare la cancellazione del tuo contenuto dell'utente associato al tuo account dai nostri database attivi. Non avremo alcuna responsabilità nei tuoi confronti per qualsiasi risoluzione dei tuoi diritti ai sensi di questi termini. Anche dopo la risoluzione dei tuoi diritti ai sensi di questi termini, le seguenti disposizioni rimarranno in vigore: Sezioni da 2 a 2.5, Sezione 3 e Sezioni da 4 a 10.</p>
<p></p>
<p>Indennizzo. Difenderai, indennizzerai e manterrai indenne noi, i nostri affiliati e il nostro personale da e contro qualsiasi reclamo, perdita e spesa (incluse le spese legali) derivanti da o correlati al tuo uso dei servizi, inclusi i tuoi contenuti, prodotti o servizi che sviluppi o offri in connessione con i servizi, e la tua violazione di questi termini o la violazione della legge applicabile.</p>
<p>Disclaimer. I SERVIZI SONO FORNITI "COSÌ COME SONO". SALVO QUANTO PROIBITO DALLA LEGGE, NOI E I NOSTRI AFFILIATI E LICENZIANTI NON FORNIAMO ALCUNA GARANZIA (ESPRESSA, IMPLICITA, STATUTORIA O ALTRIMENTI) RIGUARDO AI SERVIZI, E DECLINIAMO TUTTE LE GARANZIE INCLUSE MA NON LIMITATE ALLE GARANZIE DI COMMERCIABILITÀ, IDONEITÀ PER UN PARTICOLARE SCOPO, QUALITÀ SODDISFACENTE, NON VIOLAZIONE E QUIETO GODIMENTO, E QUALSIASI GARANZIA DERIVANTE DA QUALSIASI TRATTATIVA O USO COMMERCIALE. NON GARANTIAMO CHE I SERVIZI SARANNO ININTERROTTI, ACCURATI O PRIVI DI ERRORI, O CHE QUALSIASI CONTENUTO SARÀ SICURO O NON ANDRÀ PERSO O ALTERATO.</p>`,
			section5: `<h2>Politica sul Copyright.</h2>
<p>Rispettiamo la proprietà intellettuale altrui e chiediamo agli utenti del nostro sito di fare lo stesso. In relazione al nostro sito, abbiamo adottato e implementato una politica che rispetta la legge sul copyright, che prevede la rimozione di qualsiasi materiale che violi i diritti d'autore e la terminazione degli utenti del nostro sito online che sono trasgressori ripetuti dei diritti di proprietà intellettuale, inclusi i diritti d'autore. Se ritieni che uno dei nostri utenti stia, utilizzando il nostro sito, violando illegalmente il/i diritto/i d'autore su un'opera e desideri che il materiale presuntamente in violazione venga rimosso, le seguenti informazioni sotto forma di notifica scritta (ai sensi del 17 U.S.C. § 512(c)) devono essere fornite al nostro Agente designato per il Copyright:</p>
<p>- la tua firma fisica o elettronica;</p>
<p>- identificazione dell'opera/e protetta/e da copyright che ritieni sia/no stata/e violata/e;</p>
<p>- identificazione del materiale sui nostri servizi che ritieni sia in violazione e che richiedi di rimuovere;</p>
<p>- informazioni sufficienti per permetterci di localizzare tale materiale;</p>
<p>- il tuo indirizzo, numero di telefono e indirizzo e-mail;</p>
<p>- una dichiarazione che ritieni in buona fede che l'uso del materiale contestato non sia autorizzato dal proprietario del copyright, dal suo agente o dalla legge; e</p>
<p>- una dichiarazione che le informazioni nella notifica sono accurate e, sotto pena di spergiuro, che sei il proprietario del copyright che si ritiene sia stato violato o che sei autorizzato ad agire per conto del proprietario del copyright.</p>
<p>Si prega di notare che, ai sensi del 17 U.S.C. § 512(f), qualsiasi falsa rappresentazione di fatto materiale in una notifica scritta sottopone automaticamente la parte che presenta il reclamo alla responsabilità per eventuali danni, costi e spese legali sostenuti da noi in relazione alla notifica scritta e all'accusa di violazione del copyright.</p>`,
			section6: `<h2>Generale</h2>
<p>Questi Termini sono soggetti a occasionali revisioni e, se apportiamo modifiche sostanziali, potremmo informarti inviandoti un'e-mail all'ultimo indirizzo e-mail che ci hai fornito e/o pubblicando in modo evidente un avviso delle modifiche sul nostro sito. Sei responsabile di fornirci il tuo indirizzo e-mail più aggiornato. Nel caso in cui l'ultimo indirizzo e-mail che ci hai fornito non sia valido, l'invio dell'e-mail contenente tale avviso costituirà comunque un'efficace notifica delle modifiche descritte nell'avviso. Qualsiasi modifica ai presenti Termini sarà efficace al più presto tra trenta (30) giorni di calendario dall'invio di un avviso via e-mail o trenta (30) giorni di calendario dalla pubblicazione dell'avviso delle modifiche sul nostro sito. Queste modifiche saranno efficaci immediatamente per i nuovi utenti del nostro sito. L'uso continuato del nostro sito dopo l'avviso di tali modifiche indicherà la tua accettazione di tali modifiche e il tuo accordo a essere vincolato dai termini e condizioni di tali modifiche.Risoluzione delle controversie.Si prega di leggere attentamente questo Accordo di Arbitrato. Fa parte del tuo contratto con noi e influisce sui tuoi diritti. Contiene procedure per l'ARBITRATO OBBLIGATORIO VINCOLANTE E LA RINUNCIA ALLA CLASS ACTION.</p>
<p><strong>Applicabilità dell'Accordo di Arbitrato.</strong> Tutti i reclami e le controversie in relazione ai Termini o all'uso di qualsiasi prodotto o servizio fornito da noi, che non possono essere risolti informalmente o in tribunale per controversie di modesta entità, saranno risolti mediante arbitrato vincolante su base individuale ai sensi dei termini di questo Accordo di Arbitrato. Salvo diverso accordo, tutti i procedimenti di arbitrato si terranno in inglese. Questo Accordo di Arbitrato si applica a te e a noi, e a qualsiasi sussidiaria, affiliata, agente, dipendente, predecessore in interesse, successore e cessionario, nonché a tutti gli utenti o beneficiari autorizzati o non autorizzati dei servizi o beni forniti ai sensi dei Termini.</p>
<p><strong>Requisito di Notifica e Risoluzione Informale delle Controversie.</strong> Prima che una delle parti possa richiedere l'arbitrato, deve prima inviare all'altra parte una Notifica Scritta di Controversia descrivendo la natura e la base del reclamo o della controversia e il rimedio richiesto. Una Notifica a noi deve essere inviata al nostro indirizzo e-mail di contatto. Dopo che la Notifica è stata ricevuta, tu e noi potremmo tentare di risolvere il reclamo o la controversia informalmente. Se tu e noi non risolviamo il reclamo o la controversia entro trenta (30) giorni dalla ricezione della Notifica, una delle parti può avviare un procedimento di arbitrato. L'importo di qualsiasi offerta di accordo fatta da una delle parti non può essere rivelato all'arbitro fino a dopo che l'arbitro ha determinato l'importo del risarcimento a cui ciascuna delle parti ha diritto.</p>
<p><strong>Regole di Arbitrato.</strong> L'arbitrato sarà avviato tramite l'American Arbitration Association, un fornitore di risoluzione alternativa delle controversie che offre arbitrati come previsto in questa sezione. Se l'AAA non è disponibile per l'arbitrato, le parti dovranno concordare la selezione di un altro fornitore di ADR. Le regole del Fornitore di ADR regoleranno tutti gli aspetti dell'arbitrato, tranne nella misura in cui tali regole siano in conflitto con i Termini. Le regole di arbitrato dell'AAA per i consumatori che disciplinano l'arbitrato sono disponibili online su adr.org o chiamando l'AAA al 1-800-778-7879. L'arbitrato sarà condotto da un singolo arbitro neutrale. Qualsiasi reclamo o controversia in cui l'importo totale del risarcimento richiesto sia inferiore a diecimila dollari statunitensi (10.000,00 $ USA) può essere risolto tramite arbitrato vincolante senza comparizione, a scelta della parte che richiede il rimedio. Per i reclami o le controversie in cui l'importo totale del risarcimento richiesto sia di diecimila dollari statunitensi (10.000,00 $ USA) o superiore, il diritto a un'udienza sarà determinato dalle Regole di Arbitrato. Qualsiasi udienza si terrà in una località entro 100 miglia dalla tua residenza, a meno che tu non risieda al di fuori degli Stati Uniti, e a meno che le parti non concordino diversamente. Se risiedi al di fuori degli Stati Uniti, l'arbitro darà alle parti un ragionevole preavviso della data, ora e luogo di qualsiasi udienza orale. Qualsiasi giudizio sul premio reso dall'arbitro può essere depositato in qualsiasi tribunale di giurisdizione competente. Se l'arbitro ti concede un risarcimento superiore all'ultima offerta di accordo che abbiamo formulato prima dell'inizio dell'arbitrato, pagheremo l'importo maggiore tra il risarcimento o 2.500,00 $. Ogni parte dovrà sostenere i propri costi e esborsi derivanti dall'arbitrato e pagherà una quota uguale delle tasse e dei costi del Fornitore di ADR.</p>
<p><strong>Regole Aggiuntive per l'Arbitrato Senza Comparizione.</strong> Se è stato scelto l'arbitrato senza comparizione, l'arbitrato sarà condotto telefonicamente, online e/o basato esclusivamente su presentazioni scritte; la modalità specifica sarà scelta dalla parte che avvia l'arbitrato. L'arbitrato non comporterà alcuna comparizione personale delle parti o dei testimoni, salvo diverso accordo tra le parti.</p>
<p><strong>Limiti di Tempo.</strong> Se tu o noi ricorrete all'arbitrato, l'azione di arbitrato deve essere avviata e/o richiesta entro il termine di prescrizione e entro qualsiasi scadenza imposta dalle Regole dell'AAA per il reclamo pertinente.</p>
<p><strong>Autorità dell'Arbitro.</strong> Se viene avviato un arbitrato, l'arbitro deciderà i diritti e le responsabilità tra te e noi, e la disputa non sarà consolidata con altre questioni né unita ad altri casi o parti. L'arbitro avrà l'autorità di concedere mozioni dispositive di tutto o parte di qualsiasi reclamo. L'arbitro avrà l'autorità di concedere danni pecuniari e di concedere qualsiasi rimedio o sollievo non monetario disponibile per un individuo ai sensi della legge applicabile, delle Regole dell'AAA e dei Termini. L'arbitro emetterà un premio scritto e una dichiarazione di decisione che descrive le conclusioni essenziali su cui si basa il premio. L'arbitro ha la stessa autorità di concedere sollievo su base individuale che avrebbe un giudice in un tribunale. Il premio dell'arbitro è finale e vincolante per te e per noi.</p>
<p><strong>Rinuncia al Processo con Giuria.</strong> LE PARTI QUI RINUNCIANO AI LORO DIRITTI COSTITUZIONALI E STATUTARI DI ANDARE IN TRIBUNALE E DI AVERE UN PROCESSO DI FRONTE A UN GIUDICE O A UNA GIURIA, scegliendo invece che tutti i reclami e le controversie siano risolti tramite arbitrato ai sensi del presente Accordo di Arbitrato. Le procedure di arbitrato sono tipicamente più limitate, più efficienti e meno costose rispetto alle regole applicabili in un tribunale e sono soggette a revisione molto limitata da parte di un tribunale. Nel caso in cui dovesse sorgere una controversia tra te e noi in qualsiasi tribunale statale o federale in una causa per annullare o far rispettare un premio arbitrale o altro, TU E NOI RINUNCIAMO A TUTTI I DIRITTI A UN PROCESSO CON GIURIA, scegliendo invece che la disputa venga risolta da un giudice.</p>
<p><strong>Rinuncia ad Azioni Collettive o Consolidate.</strong> Tutti i reclami e le controversie nell'ambito del presente accordo di arbitrato devono essere arbitrati o litigati su base individuale e non su base collettiva, e i reclami di più di un cliente o utente non possono essere arbitrati o litigati congiuntamente o consolidati con quelli di qualsiasi altro cliente o utente.</p>
<p><strong>Riservatezza.</strong> Tutti gli aspetti del procedimento arbitrale saranno strettamente confidenziali. Le parti concordano di mantenere la riservatezza a meno che non sia altrimenti richiesto dalla legge. Questo paragrafo non impedisce a una parte di presentare a un tribunale qualsiasi informazione necessaria per far rispettare il presente Accordo, far rispettare un premio arbitrale o richiedere un rimedio ingiuntivo o equitativo.</p>`,
			section7: `<p><strong>Separabilità.</strong> Se una qualsiasi parte o parti di questo Accordo di Arbitrato vengono trovate invalide o inapplicabili da un tribunale di giurisdizione competente ai sensi della legge, tali specifiche parti non avranno efficacia e saranno separate e il resto dell'Accordo continuerà ad avere piena efficacia.</p>
<p><strong>Diritto di Rinuncia.</strong> Qualsiasi o tutti i diritti e le limitazioni stabiliti in questo Accordo di Arbitrato possono essere rinunciati dalla parte contro la quale viene presentato il reclamo. Tale rinuncia non invaliderà né influenzerà alcuna altra parte di questo Accordo di Arbitrato.</p>
<p><strong>Sopravvivenza dell'Accordo.</strong> Questo Accordo di Arbitrato sopravviverà alla cessazione del tuo rapporto con noi.</p>
<p><strong>Tribunale per Controversie di Modesta Entità.</strong> Nonostante quanto sopra, tu o noi possiamo intentare un'azione individuale nel tribunale per controversie di modesta entità.</p>
<p><strong>Rimedio Equitativo di Emergenza.</strong> Nonostante quanto sopra, una delle parti può richiedere un rimedio equitativo di emergenza presso un tribunale statale o federale per mantenere lo status quo in attesa dell'arbitrato. Una richiesta di misure provvisorie non sarà considerata una rinuncia a qualsiasi altro diritto o obbligo ai sensi di questo Accordo di Arbitrato.</p>
<p><strong>Reclami Non Soggetti ad Arbitrato.</strong> Nonostante quanto sopra, i reclami di diffamazione, violazione del Computer Fraud and Abuse Act e violazione o appropriazione indebita di brevetti, diritti d'autore, marchi o segreti commerciali dell'altra parte non saranno soggetti a questo Accordo di Arbitrato.</p>
<p>In qualsiasi circostanza in cui il presente Accordo di Arbitrato consente alle parti di litigare in tribunale, le parti accettano di sottoporsi alla giurisdizione personale dei tribunali situati nella Contea di Netherlands, California, a tali fini.</p>
<p>Il Sito potrebbe essere soggetto alle leggi sul controllo delle esportazioni degli Stati Uniti e potrebbe essere soggetto a regolamenti di esportazione o importazione in altri paesi. Accetti di non esportare, riesportare o trasferire, direttamente o indirettamente, qualsiasi dato tecnico statunitense acquisito da noi, o qualsiasi prodotto che utilizzi tali dati, in violazione delle leggi o dei regolamenti statunitensi sulle esportazioni.</p>`,
			section8: `<p><strong>Comunicazioni Elettroniche.</strong> Le comunicazioni tra te e noi utilizzano mezzi elettronici, sia che tu usi il sito o ci invii email, sia che noi pubblichiamo avvisi sul sito o comunichiamo con te via email. Ai fini contrattuali, tu (a) acconsenti a ricevere comunicazioni da parte nostra in forma elettronica; e (b) accetti che tutti i termini e condizioni, accordi, avvisi, divulgazioni e altre comunicazioni che ti forniamo elettronicamente soddisfino qualsiasi obbligo legale che tali comunicazioni soddisferebbero se fossero in forma scritta cartacea.</p>
<p><strong>Termini Completi.</strong> Questi Termini costituiscono l'intero accordo tra te e noi riguardo l'uso del sito. La nostra mancata applicazione o esecuzione di qualsiasi diritto o disposizione di questi Termini non opererà come una rinuncia a tale diritto o disposizione. I titoli delle sezioni in questi Termini sono solo per convenienza e non hanno alcun effetto legale o contrattuale. La parola "incluso" significa "incluso senza limitazione". Se una qualsiasi disposizione di questi Termini è ritenuta invalida o inapplicabile, le altre disposizioni di questi Termini rimarranno intatte e la disposizione invalida o inapplicabile sarà considerata modificata in modo da essere valida e applicabile nella massima misura consentita dalla legge. Il tuo rapporto con noi è quello di un contraente indipendente, e nessuna delle parti è agente o partner dell'altra. Questi Termini, e i tuoi diritti e obblighi qui contenuti, non possono essere assegnati, subappaltati, delegati o altrimenti trasferiti da te senza il nostro previo consenso scritto, e qualsiasi assegnazione, subappalto, delega o trasferimento in violazione di quanto sopra sarà nullo e privo di effetto. Noi possiamo assegnare liberamente questi Termini. I termini e le condizioni stabiliti in questi Termini saranno vincolanti per gli assegnatari.</p>
<p><strong>La Tua Privacy.</strong> Per favore leggi la nostra Politica sulla Privacy.</p>
<p><strong>Informazioni su Copyright/Marchi.</strong> Copyright ©. Tutti i diritti riservati. Tutti i marchi, loghi e marchi di servizio visualizzati sul sito sono di nostra proprietà o di proprietà di terzi. Non ti è permesso utilizzare questi Marchi senza il nostro previo consenso scritto o il consenso del terzo che può possedere i Marchi.</p>
<h2>Informazioni di Contatto</h2>
<p>Email: watch_support@adflash.cn</p>`
		},
		privacy: {
			section1: `<p>Proteggere la tua privacy è importante per noi. Speriamo che la seguente dichiarazione ti aiuti a capire come i nostri prodotti trattano le informazioni personali identificabili che occasionalmente potresti fornirci via Internet.</p>
<p>Sulla base delle nostre app e dei servizi associati, puoi richiedere l'uso di un servizio funzionale speciale fornito da terzi. Se è necessario iscriversi e utilizzare qualsiasi servizio funzionale speciale, leggi attentamente i termini di servizio per tale servizio funzionale speciale. Accettando o utilizzando qualsiasi servizio funzionale speciale, riconosci di aver letto e accettato il presente Accordo e i termini di servizio per tale servizio funzionale speciale e accetti di essere vincolato da essi.</p>
<p>A volte abbiamo bisogno di informazioni per fornire i servizi che richiedi, questa dichiarazione sulla privacy si applica ai nostri prodotti.</p>
<h2>Informazioni che il Team Raccoglie e Riceve.</h2>
<p>Quando utilizzi il Software e i Servizi, potremmo automaticamente raccogliere e memorizzare i seguenti tipi di informazioni:</p>
<p>1. Informazioni Tecniche. Alcune informazioni tecniche riguardanti il tuo dispositivo e l'uso del Software e dei Servizi e altri software e applicazioni di terzi installati sul tuo dispositivo (il "Altro Software"), inclusi il tipo, nome e versione del sistema operativo e del Altro Software, comprese, senza limitazioni, le loro interazioni con il Software e i Servizi, ISP (Internet Service Provider), posizione (GPS), indirizzo di protocollo Internet (IP), identificatori anonimi dell'utente, configurazione e identificatori del dispositivo (IDFA (Identifier for Advertisers utilizzato in iOS), Advertising ID (Identifier for Advertisers utilizzato in Android) e simili), versione del Software e dei Servizi, e la tua configurazione e impostazioni del Software e dei Servizi;</p>
<p>2. Informazioni sull'Utilizzo. Alcune informazioni relative all'uso e all'interazione con il tuo dispositivo, incluso il Software e i Servizi e l'Altro Software, come quando e come utilizzi il Software e i Servizi e l'Altro Software. Ad esempio, le applicazioni che possiedi e le applicazioni che utilizzi e quanto frequentemente le utilizzi. Gli annunci pubblicitari che visualizzi, utilizzi e accedi, come li utilizzi e la tua risposta ad essi;</p>
<p>3. Reti Sociali. Le tue interazioni sui social network tramite il Software e i Servizi;</p>`,
			section2: `<p>4. Informazioni Relative alla Registrazione. Informazioni che fornisci quando ti registri per il Software & Servizi (se applicabile), come nome, indirizzi di casa o lavoro, indirizzo e-mail, numeri di telefono e fax, data di nascita e genere;</p>
<p>5. Informazioni Relative al Supporto. Se comunichi con noi tramite la nostra sezione di supporto o via email, potremmo anche raccogliere il tuo indirizzo email, nome e qualsiasi informazione fornita volontariamente da te in relazione al supporto. Quando utilizzi il Software & Servizi, generiamo automaticamente un ID utente interno anonimo, che viene assegnato al tuo uso del Software & Servizi (il "User ID") e può essere combinato con qualsiasi delle informazioni sopra indicate.</p>
<p>6. Informazioni sul Dispositivo</p>
<p>Raccogliamo informazioni sul tuo dispositivo, inclusi il modello hardware, il sistema operativo e la versione, identificatori unici del dispositivo (inclusi SSAID, GAID), tipo e lingua del browser, numero di telefono del dispositivo mobile e informazioni sulla rete mobile, International Mobile Equipment Identity ("IMEI"), Identifier for Advertising ("IDFA"), Identifier for Vendor ("IDFV"), Integrated Circuit Card Identifier ("ICCID"), indirizzo Media Access Control ("MAC"), modello e risoluzione, che saranno utilizzati da noi per calcolare il numero di dispositivi che utilizzano i nostri prodotti e Servizi e analizzare i dati sui modelli di dispositivi e sull'adattamento grafico. Puoi scegliere di non fornire alcune informazioni, ma in tal caso potresti non essere in grado di usufruire di molte delle nostre APP. Raccogliamo anche alcune informazioni sui dispositivi che ci aiuteranno a diagnosticare problemi nell'eventualità (si spera rara) in cui si verifichi un crash o un altro problema durante l'uso dei nostri Servizi.</p>
<p>7. Fotocamera e Foto</p>
<p>Molti dei nostri Servizi richiedono la raccolta di immagini e altre informazioni dalla fotocamera e dalle foto del tuo dispositivo. Accederemo alla tua fotocamera e foto solo dopo che ci avrai dato il tuo consenso. Puoi scegliere di non fornire queste informazioni, ma in tal caso potresti non essere in grado di usufruire di molti dei nostri Servizi.</p>
<p>8. Memoria Locale</p>
<p>Potremmo raccogliere e memorizzare informazioni localmente sul tuo dispositivo utilizzando meccanismi come lo storage web del browser (incluso HTML 5) e le cache dei dati delle applicazioni. Le informazioni che memorizziamo non saranno condivise con terzi.</p>
<p>9. INFORMAZIONI NON PERSONALI</p>`,
			section3: `<p>Le informazioni non personali sono dati in una forma che non consente un'associazione diretta con alcun individuo specifico, come il tuo ID Android, il modello CPN, la dimensione della memoria, il numero IMEI del telefono, il modello del telefono, la rom, il nome dell'applicazione installata e il nome del pacchetto, l'operatore telefonico, la posizione, l'installazione, la disinstallazione, la frequenza d'uso, ecc.</p>
<p>Potremmo raccogliere e utilizzare informazioni non personali nelle seguenti circostanze. Per capire meglio il comportamento degli utenti, risolvere problemi nei prodotti e servizi, migliorare i nostri prodotti, servizi e pubblicità, potremmo raccogliere informazioni non personali come il nome dell'applicazione installata e il nome del pacchetto, i dati di installazione, la frequenza d'uso, il paese, l'attrezzatura e il canale.</p>
<h2>Come il Team Utilizza le Tue Informazioni?</h2>
<p>Il Team può utilizzare le informazioni che raccoglie per scopi analitici, commerciali e operativi nei seguenti modi:</p>
<p>1. per operare e aggiornare il Software e i Servizi e fornirti funzionalità e caratteristiche esistenti e nuove;</p>
<p>2. per migliorare e personalizzare il Software e i Servizi;</p>
<p>3. per fornirti contenuti personalizzati, anche tramite la consegna di annunci pubblicitari mirati basati sugli interessi da parte nostra o dei nostri partner;</p>
<p>4. per mantenere e migliorare la qualità e il funzionamento del Software e dei Servizi;</p>
<p>5. per notificarti aggiornamenti del Software e dei Servizi;</p>
<p>6. per fornirti supporto e gestire i tuoi reclami e/o feedback;</p>
<p>7. per intraprendere qualsiasi azione in caso di una disputa che ti coinvolga in relazione al Software e ai Servizi;</p>`,
			section4: `<p>8. per applicare i termini e le condizioni applicabili al Software e ai Servizi e per prevenire attività illecite e uso improprio degli stessi;</p>
<p>9. per rispettare e soddisfare qualsiasi legge, regolamento, procedimento legale o richiesta governativa applicabile. Condivisione delle Informazioni con Terze Parti.</p>
<h2>Privacy dei Bambini</h2>
<p>Questi Servizi non si rivolgono a persone di età inferiore ai 13 anni. Non raccolgo consapevolmente informazioni personali identificabili da bambini di età inferiore ai 13 anni. Nel caso in cui scoprissi che un bambino di età inferiore ai 13 anni mi ha fornito informazioni personali, le elimino immediatamente dai nostri server. Se sei un genitore o un tutore e sei a conoscenza che tuo figlio ci ha fornito informazioni personali, ti prego di contattarmi in modo che possa intraprendere le azioni necessarie.</p>
<h2>Modifiche a Questa Politica sulla Privacy</h2>
<p>Potrei aggiornare la nostra Politica sulla Privacy di tanto in tanto. Pertanto, ti consigliamo di rivedere periodicamente questa pagina per eventuali cambiamenti. Ti notificherò di qualsiasi modifica pubblicando la nuova Politica sulla Privacy su questa pagina. Queste modifiche sono efficaci immediatamente dopo essere state pubblicate su questa pagina.</p>
<h2>Contattaci</h2>
<p>Se hai domande o suggerimenti sulla mia Politica sulla Privacy, non esitare a contattarmi. Email: watch_support@adflash.cn</p>`,


		},
		refund: {
			section1: `<p>Grazie per il tuo acquisto. Se non sei completamente soddisfatto del tuo acquisto, saremo lieti di aiutarti.</p>
<p>Per avviare il processo di rimborso, segui i passaggi seguenti.</p>
<p>Prodotti in abbonamento (ad esempio, Abbonamento Base)</p>
<p>Se smetti di usare ma dimentichi di annullare il tuo abbonamento, di solito saremo felici di rimborsare il tuo pagamento di abbonamento più recente, purché tu non abbia utilizzato nessuna sessione o creato immagini dopo che il pagamento è stato effettuato.</p>
<p>Per richiedere un rimborso, inviaci una richiesta di rimborso tramite il tuo account e tramite il modulo di feedback. Potremmo aver bisogno di chiederti informazioni specifiche per aiutarci a confermare la tua identità.</p>
<p>Prodotti digitali (ad esempio, pacchetti di tempo, abbonamenti)</p>
<p>Non verranno concessi rimborsi se i pacchetti di tempo e gli abbonamenti sono stati utilizzati. Non offriamo rimborsi parziali o proporzionati.</p>
<p>Hai 14 giorni di calendario dalla data della transazione per richiedere un rimborso.</p>
<p>Una volta ricevuta la tua richiesta di rimborso, il nostro personale controllerà lo stato della tua iscrizione per determinare se sei idoneo per un rimborso.</p>`,
			section2: `<p>Se sei approvato, il tuo rimborso verrà elaborato. Il rimborso verrà accreditato sul metodo di pagamento originale utilizzato per l'acquisto.</p>
<p>Rimborsi ritardati o mancanti</p>
<p>Se non hai ancora ricevuto il tuo rimborso, controlla di nuovo il tuo conto bancario.</p>
<p>Quindi contatta la società della tua carta bancaria, potrebbe essere necessario del tempo perché il rimborso venga ufficialmente rilasciato.</p>
<p>Successivamente, contatta la tua banca. Solitamente occorre un po' di tempo per l'elaborazione prima che il rimborso venga rilasciato.</p>
<p>Se hai fatto tutto questo e non hai ancora ricevuto il rimborso, contattaci all'indirizzo watch_support@adflash.cn</p>
<p>Articoli in saldo</p>
<p>Saranno rimborsati soltanto gli articoli a prezzo regolare, purtroppo gli articoli in saldo non possono essere rimborsati.</p>`,
		},
		us: {
			section1: `<p>Informazioni sulla Società</p>
<p>Nome: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>Indirizzo: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>Email: watch_support@adflash.cn</p>`,
		},
	}
}