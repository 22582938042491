<template>
  <div>
    <div class="container">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  // height: calc(100vh - 15vw);
  position: absolute;

  // top: 15vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.topBar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  
  height: 15vw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f6ff;
  padding: 0 4vw;
  // position: fixed;
  // top: 0;
  .left-setting {
    img {
      width: 8vw;
      height: 8vw;
    }
  }
  .right-setting {
    display: flex;
    > div {
      height: 10vw;
      margin-left: 4vw;
      img {
        height: 10vw;
      }
    }
    .record {
      position: relative;
      > p {
        font-size: 4vw;
        font-weight: bold;
        color: #7541ff;
        position: absolute;
        right: 50%;
        top: 50%;
        margin: 0;
        transform: translateX(100%) translateY(-50%);
      }
    }
  }
}
</style>
