import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import stateTest from './state/test'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import shtest from './state/shtest'

var state = null;
if (process.env.VUE_APP_BASE_PATH === 'production') {
    state = stateTest;
}else if(process.env.VUE_APP_BASE_PATH === 'shcs'){
    state = shtest;
}else{
    state = stateTest;
}

const store = new Vuex.Store({
    state,getters,mutations,actions
})

export default store

