<template>
  <div>
    <div class="container">
      <keep-alive>
        <!-- exclude 不生效 -->
        <transition name="slide-left">
          <router-view></router-view>
        </transition>
      </keep-alive>
    </div>
    <div class="tabBar">
      <div
        :class="['tabBar-item', tabActive == item.to ? 'itemActive' : '']"
        v-for="(item, index) in tabList"
        :key="index"
        @click="tabActiveHandle(item.to)"
      >
        <div class="tabBar-icon">
          <img
            :src="tabActive == item.to ? item.iconOn : item.iconOff"
            alt=""
          />
        </div>
        <div class="tabBar-name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import PageSlide from '@/components/PageSlide/PageSlide.vue'

export default {
  components: {
    PageSlide,
  },  data() {
    return {
      tabActive: "/",
      tabList: [
        {
          name: this.$t('navigator.message1'),
          value: this.$t('navigator.message1'),
          iconOn: require("@/assets/drawable-xxhdpi/icon_h_chat.png"),
          iconOff: require("@/assets/drawable-xxhdpi/icon_h_chat_nor.png"),
          to: "/",
        },
        {
          name: this.$t('navigator.message2'),
          value: this.$t('navigator.message2'),
          iconOn: require("@/assets/drawable-xxhdpi/icon_smartai.png"),
          iconOff: require("@/assets/drawable-xxhdpi/icon_smartai_nor.png"),
          to: "/SmartAI",
        },
        // {
        //   name: "Discover",
        //   value: "Discover",
        //   iconOn: require("@/assets/drawable-xxhdpi/icon_h_discover.png"),
        //   iconOff: require("@/assets/drawable-xxhdpi/icon_h_discover_nor.png"),
        //   to: "/Discover",
        // },
        // {
        //   name: "Create",
        //   value: "Create",
        //   iconOn: require("@/assets/drawable-xxhdpi/icon_h_create.png"),
        //   iconOff: require("@/assets/drawable-xxhdpi/icon_h_create_nor.png"),
        //   to: "/Create",
        // },
        // {
        //   name: "Points",
        //   value: "Points",
        //   iconOn: require("@/assets/drawable-xxhdpi/icon_h_points.png"),
        //   iconOff: require("@/assets/drawable-xxhdpi/icon_h_points_nor.png"),
        //   to: "/Points",
        // },
        {
          name: this.$t('navigator.message3'),
          value: this.$t('navigator.message3'),
          iconOn: require("@/assets/drawable-xxhdpi/icon_h_mine.png"),
          iconOff: require("@/assets/drawable-xxhdpi/icon_h_mine_nor.png"),
          to: "/Mine",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.tabActive = this.$route.path;
  },
  methods: {
    tabActiveHandle(val) {
      this.tabActive = val;
      this.$router.push(val).catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err; // 处理其他导航错误
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  // height: calc(100vh - 15vw);
  top: 0;
  bottom: 20vw;
  left: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
}
.tabBar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f3f6ff;
  height: 20vw;
  border-radius: 6vw 6vw 0 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2vw 0;
  .tabBar-item {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .tabBar-icon {
      flex: 1;
      color: #adb3c9;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 8vw;
        height: 8vw;
      }
    }
    .tabBar-name {
      text-align: center;
      font-size: 3vw;
      color: #bcc1d3;
    }
  }
  .itemActive {
    .tabBar-icon {
      color: #8550de;
    }
    .tabBar-name {
      color: #16161b;
    }
  }
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  position: absolute;
  width: 100%;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(100%, 0);

}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-100%, 0);
}
</style>
