import Vue from "vue";
import VueRouter from "vue-router";

import topBar from "@/layout/topBar";
import tabBar from "@/layout/tabBar";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: topBar,
    children: [
      {
        path: "",
        component: tabBar,
        children: [
          {
            name: "Chat",
            path: "",
            component: () => import("@/views/Chat/index.vue"),
          },
          {
            path: "/SmartAI",
            component: () => import("@/views/SmartAI/index.vue")
          },
          {
            name: "Discover",
            path: "/Discover",
            component: () => import("@/views/Discover/index.vue"),
          },
          {
            name: "Create",
            path: "/Create",
            component: () => import("@/views/Create/index.vue"),
          },
          {
            name: "Points",
            path: "/Points",
            component: () => import("@/views/Points/index.vue"),
          },
          {
            name: "Mine",
            path: "/Mine",
            component: () => import("@/views/Mine/index.vue"),
          },
        ],
        
      },
     
    ],
  },
  {
    name: "Setting",
    path: "/Setting",
    component: () => import("@/views/Mine/setting/index.vue"),
  },
  {
    name: "MySubscription",
    path: "/SettingMySubscription",
    component: () => import("@/views/Mine/setting/MySubscription.vue"),
  },
  {
    name: "service",
    path: "/service",
    component: () => import("@/views/Mine/setting/service.vue"),
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("@/views/Mine/setting/privacy.vue"),
  },
  {
    name: "smartAiPages",
    path: "/smartAiPages",
    component: () => import("@/views/Chat/components/smartAiPages.vue"),
  },
  {
    name: "smartAiPagesDEV",
    path: "/smartAiPagesDEV",
    component: () => import("@/views/Chat/components/smartAiPagesDEV.vue"),
  },
  {
    name: "smartAiReadonly",
    path: "/smartAiReadonly",
    component: () => import("@/views/Chat/components/smartAiReadonly.vue"),
  },
  {
    name: "watchPages",
    path: "/watchPages",
    component: () => import("@/views/Chat/components/watchPages.vue"),
  },
  {
    name: "mineWatchPages",
    path: "/mineWatchPages",
    component: () => import("@/views/Mine/components/mineWatchPages.vue"),
  },
  {
    name: "smartAiInfo",
    path: "/smartAiInfo",
    component: () => import("@/views/Chat/components/smartAiInfo.vue"),
  },
  {
    name: "smartAiInfo2",
    path: "/smartAiInfo2",
    component: () => import("@/views/Chat/components/smartAiInfo copy.vue"),
  },
  {
    name: "aiFriendPages",
    path: "/aiFriendPages",
    component: () => import("@/views/Chat/components/aiFriendPages.vue"),
  },
  {
    name: "addSA",
    path: "/addSA",
    component: () => import("@/views/Create/components/addSA.vue"),
  },
  {
    name: "selectSound",
    path: "/selectSound",
    component: () => import("@/views/Create/components/selectSound.vue"),
  },
  {
    name: "searchPage",
    path: "/searchPage",
    component: () => import("@/views/Chat/components/searchPage.vue"),
  },
  {
    name: "devePage",
    path: "/devePage",
    component: () => import("@/views/devePage/devePage.vue"),
  },
  {
    name: "downloadImg",
    path: "/downloadImg",
    component: () => import("@/views/Chat/components/downloadImg.vue"),
  },
  {
    path: '/notice',
    component: () => import('@/views/Mine/setting/notice.vue')
  },
  {
    path: '/notice/detail/:id',
    component: () => import('@/views/Mine/setting/noticeDetail.vue')
  },
  {
    path: '/problem',
    component: () => import('@/views/Mine/setting/problemPage.vue')
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to !== from) {
    next();
  }
});


export default router;
