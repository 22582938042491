module.exports = {
	language: "日语",

	navigator: {
		message1: "チャット",
		message2: "スマートAI",
		message3: "マイン",
	},
	chat: {
		adjustment: {
			message1: "調整",
			message2: "フォントサイズのプレビューを手伝ってください",
			message3: "スライダーをドラッグすることでフォントサイズを調整できます。設定すると、会話のフォントサイズが変更されます。",

		},
		aiFriendPages: {
			message1: "こんにちは、新しい友達です！初めまして。あなたのすべての質問に答え、仕事と勉強を手伝い、いつでもチャットできます。さて、最初に何を質問したいですか？",
			message2: "質問",
			message3: "このコンテンツは大規模なモデルによって生成されているため、",
			message4: "完全な真実性は保証できません。",
			message5: "公式",
		},
		downloadImg: {
			message1: "ダウンロード",
			message2: "アルバムに正常に保存されました",
			message3: "",
			message4: "",
			message5: "",
		},
		searchPage: {
			message1: "ローディング",
			message2: "現在利用可能な検索結果はありません",
			message3: "",
			message4: "",
			message5: "",
		},
		smartAiInfo: {
			message1: "フォントサイズの調整",
			message2: "チャット履歴をクリア",
			message3: "現在のチャット記録をクリアするかどうか",
			message4: "キャンセル",
			message5: "確認",
		},
		smartAiPages: {
			message1: "画像が生成されています",
			message2: "カウントダウンが終了するか、どこでもクリックして識別",
			message3: "クリックして話す",
			message4: "この内容はAIによって生成されており、参考のみです。",
			message5: "インテリジェントエージェントが削除されました",
		},
		smartAiPagesDEV: {
			message1: "画像が生成されています",
			message2: "カウントダウンが終了するか、どこでもクリックして識別",
			message3: "クリックして話す",
			message4: "この内容はAIによって生成されており、参考のみです。",
			message5: "インテリジェントエージェントが削除されました",
			message6:"このスマート AI はファイルと画像の送信をサポートしていません",
			message7:"このスマート AI はファイルの送信をサポートしていません",
			message8:"現在会話が進行中です",
			message9:"AI 描画プロンプト ワードは規制に違反しています。準拠したプロンプト描画ワードを入力してください。",
			message10:"この画像は規制に違反しており、認識できません。",
						message11:"この会話は制限に達しました。新しい Al チャット セッションを開始してください",
			message12:"新しいダイアログ",
			message13:"サーバーがビジー状態のため、もう一度お試しください。これによりクレジットは消費されません。",
			message14:"質問と回答の数がなくなりました。引き続き使用するには、時計にアクセスして QR コードをスキャンしてください。",
		},
		smartAiReadonly: {
			message1: "公式",
			message2: "こんにちは、新しい友達です！初めまして。あなたのすべての質問に答え、仕事と勉強を手伝い、いつでもチャットできます。さて、最初に何を質問したいですか？",
			message3: "スライダーをドラッグすることでフォントサイズを調整できます。設定すると、会話のフォントサイズが変更されます。",
			message4: "こんにちは、新しい友達です！初めまして。あなたのすべての質問に答え、仕事と勉強を手伝い、いつでもチャットできます。さて、最初に何を質問したいですか？",
			message5: "",
		},
		watchPages: {
			message1: "ウォッチ1",
			message2: "公式",
			message3: "ここにいます。どういったご用件でしょうか",
			message4: "スマートAIを削除",
			message5: "チャット履歴を削除",
		},
		index: {
			message1: ["推薦する", "勉強", "ライフ", "創造", "絵画", "作業"],
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	components: {
		AIFriend: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		chatAI: {
			message1: "まだ会話を作成していません",
			message2: "ウォッチ0",
			message3: "お気に入りをキャンセル",
			message4: "スマートAIをwatchNameに追加しますか？",
			message5: "キャンセル",
			message6: "確認",
		},
		MyAI: {
			message1: "申し訳ありません。まだお気に入りはありません",
			message2: "削除",
			message3: "お気に入りをキャンセル",
			message4: "AIアートをこのウォッチに追加しますか？",
			message5: "AI植物学者を削除する",
			message6: "キャンセル",
			message7: "確認",
		},
		SmartAI: {
			message1: "申し訳ありません。まだお気に入りはありません",
			message2: "削除",
			message3: "お気に入りをキャンセル",
			message4: "スマートAIを追加しますか ",
			message5: "スマートAIを削除する",
			message6: "キャンセル",
			message7: "確認",
		},
		SmartAI2: {
			message1: "申し訳ありません。まだお気に入りはありません",
			message2: "スマートAIを削除",
			message3: "お気に入りをキャンセル",
			message4: "スマートAIを追加しますか ",
			message5: "スマートAIを削除する",
			message6: "キャンセル",
			message7: "確認",
		},
		Watch: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	//做到这里
	create: {
		addSA: {
			message1: "作成",
			message2: "アバター追加",
			message3: "名前",
			message4: "説明を設定",
			message5: "言語",
			message6: "サウンド",
			message7: "作成",
			message8: "AI生成",
			message9: "アルバムアップロード",
			message10: "キャンセル",
			message11: "例: あなたは学生を学習に駆り立てる方法を持つ経験豊富な英語教師です。ユーモアと実生活の例を使って会話を面白くします。",
			message12: "この画像はAIアバターに使用されます",
		},
		aiGeneration: {
			message1: "AI生成",
			message2: "参考画像を追加（オプション）",
			message3: "顔がはっきりしている写真をアップロードして画像の生成を助けます",
			message4: "名前",
			message5: "作成",
			message6: "画像の説明を追加",
		},
		createSA: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		selectLanguage: {
			message1: "言語",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		selectSound: {
			message1: "サウンド",
			message2: "現在選択されているサウンドはありません",
			message3: "ジェントルピーチ",
			message4: "スマートAIを削除",
			message5: "",
		},
		index: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	devePage: {

	},
	discover: {

	},
	support: {
		message1: "スマートAI",
		message2: "私の創造物",
		message3: "開ける",
		message4: "誰でも話せる",
		message5: "プライベート",
		message6: "あなただけが話せる",
		message7: "名前を入力",
		message8: "AIを検索",
		message9: "メッセージを送ります",
		message10: "標準",
	},
	mine: {
		mineWatchPages: {
			message1: "スマートAIを削除",
			message2: "本当にスマートAIをウォッチから削除しますか？",
			message3: "キャンセル",
			message4: "ホームページがいっぱいです",
			message5: "ウォッチのホームページでスマートAIの上限に達しました。新しいものを追加する前に、いくつかのスマートAIを削除してください。",
			message6: "名前を編集",
			message7: "確認",
			message8: "私のウォッチ",
			message9: "iMei",
			message10: "ウォッチスマートAI",
			message11: "ウォッチ設定",
			message12: "ホームページに追加",
			message13: "Al含有量評価",
			message14: "時計のホームページから削除する",
		},
		settingindex: {
			message1: "設定",
			message2: "AIモード",
			message3: "言語",
			message4: "注文履歴",
			message5: "問題フィードバック",
			message6: "お知らせ",
			message7: "利用規約",
			message8: "プライバシーポリシー",
			message9: "開けなかった",
			message10: "注文履歴を開くことができません。時計のQRコードをスキャンして開いてください。",
			message11: "確認",
		},
		Language: {
			message1: "言語",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		MySubscription: {
			message1: "モード選択スマートAI",
			message2: "プロ",
			message3: "標準版",
			message4: "",
			message5: "",
		},
		index: {
			message1: "私のウォッチ",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	points: {},
	smartai: {
		collectFriendlyAI: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collectInspiration: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collectSmartAi: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collect: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		discoverAF: {
			message1: ["私の", "おすすめ", "女性", "男性", "アニメ", "ゲーム"],
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		discoverSA: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		inspiration: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		mycreation: {
			message1: "私のスマートAIを作成",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	Toast: {
		message1: "ジャービス",
		message2: "コピー成功",
		message3: "このスマートAIはファイルと画像の送信をサポートしていません",
		message4: "現在の会話が進行中です",
		message5: "名前は少なくとも4文字必要です",
		message6: "内容は少なくとも4文字必要です",
		message7: "成功",
		message8: "エラー",
		message9: "名前",
		message10: "プロンプト",
		message11: "プライバシー",
		message12: "言語",
		message13: "AIサウンドコード",
		message14: "画像",
		message15: "空",
		message16: "スマートAI移動中",
		message17: "追加成功",
		message18: "削除成功",
		message19: "スマートAIの追加に失敗しました",
		message20: "不安定なインターネット接続。もう一度お試しください。",
		message21: "スマートAIの追加完了",
		message22: "スマートAIの削除に失敗しました",
		message23: "不安定なインターネット接続。もう一度スマートAIを追加してください。",
		message24: "AI植物学者を削除",
		message25: "スマートAIの削除完了",
		message26: "削除に失敗しました。不安定なインターネット接続。もう一度スマートAIを追加してください。",
		message27: "このスマートAIはファイルと",
		message27: "コピーに失敗しました。手動で選択してコピーしてください！",
		message28: "作成中",
		message29: "値が空です",
		message30: "スマートAI削除中",
		message31: "スマートAI追加中",
		message32: "スマートAI削除中",
		message33: "一般質問及び答弁は定数を使い果たしました。 ",
		message34: "高度な Q&A セッションの数を使い果たしました。",
	},
	setting: {
		index: {
			title: "設定",
			problem: "問題のフィードバック",
			order: "注文記録",
			notice: "通知",
			language: "言語",
			clear: "キャッシュをクリア",
			clearTip: "キャッシュをクリアしますか？",
			confirm: "確認",
			cancel: "キャンセル",
		},
		language: {
			title: "言語",
			langList: ["簡体字中国語", "繁体字中国語", "英語", "アラビア語", "研磨", "ペルシア語", "デンマーク語", "ドイツ人", "ロシア", "フランス語", "オランダの",
				"チェコ語", "ルーマニア語", "ポルトガル語", "日本語", "タイ語", "トルコ語", "スペイン語", "ギリシャ語", "イタリアの", "ベトナム語", "ヒンディー語"
			],
		},
		order: {
			title: "注文記録",
			success: "取得成功",
			unpaid: "未払",
			paid: "支払い済み",
			expired: "期限切れ",
			refunded: "返金済み",
			pTime: "支払い時間",
			eTime: "有効期限",
			pMethod: "支払い方法",
			pObject: "支払対象",
			oNumber: "注文番号",
			pType: "パッケージタイプ",
			oTime: "取得時間",
			watch: "IMEI表示",
			delete: "注文を削除",
			refund: "返金をリクエスト",
		},
		problem: {
			title: "問題のフィードバック",
			feedback: "フィードバックタイプ",
			please: "選んでください",
			content: "内容",
			improve: "機能要求や製品への苦情など、ご提案をお書きください。改善に努めます",
			upload: "画像をアップロード",
			submit: "送信",
			general: "一般フィードバック",
			purchase: "購入関連",
			bug: "バグ報告",
			suggestion: "提案",
			other: "その他",
		},
		notice: {
			title: "通知",
			type: "通知タイプ：",
			time: "通知時間："
		}
	},
	policy: {
		title: {
			terms: "利用規約",
			about: "約",
			service: "サービス利用規約",
			privacy: "プライバシーポリシー",
			refund: "返金ポリシー",
			us: "私たちについて",
		},
		service: {
			section1: `<p>ウェブサイト https://aiwatchstore.org は、HONG KONG ADFLASH MEDIA LIMITEDの著作物です。サイトの特定の機能には、追加のガイドライン、利用規約、または規則が適用される場合があり、それらはその機能に関連してサイト上に掲載されます。</p>
<p>これらの追加の規約、ガイドライン、および規則は、参照によって本利用規約に組み込まれます。</p>
<p>この利用規約は、サイトの使用に関する法的拘束力のある条件を説明します。サイトにログインすることで、これらの条件に従うことに同意し、これらの条件に従う権限と能力があることを表明します。サイトへのアクセスには18歳以上である必要があります。これらの条件のすべての条項に同意しない場合は、ログインまたはサイトの使用をしないでください。</p>
<p>これらの条件は、個人ベースでの仲裁（第10.2節）を使用して紛争を解決し、紛争が発生した場合に利用できる救済手段を制限することを要求します。</p>
<h2>サイトへのアクセス</h2>
<p><strong>これらの条件に基づきます。</strong>当社は、お客様に対して、個人的かつ非商業的な使用のためにのみ、サイトへのアクセスを提供するための非譲渡、非独占、取消可能、限定的なライセンスを付与します。</p>
<p>当社は、通知の有無にかかわらず、サイトを変更、一時停止、または中止する権利を有します。お客様は、当社がサイトまたはその一部の変更、中断、または終了に対して、お客様や第三者に対して責任を負わないことを認めます。</p>
<p><strong>サポートやメンテナンスはありません。</strong>当社は、サイトに関連してお客様にサポートを提供する義務を負わないことに同意します。</p>
<p>お客様が提供する可能性のあるユーザーコンテンツを除き、サイトおよびそのコンテンツに含まれるすべての知的財産権（著作権、特許、商標、営業秘密を含む）が当社または当社のサプライヤーに帰属することを理解しています。これらの利用規約およびサイトへのアクセスは、第2.1節に明確に示された限定的なアクセス権を除き、いかなる知的財産権に対する権利、所有権、利益も付与しません。当社およびそのサプライヤーは、本利用規約で明示的に付与されていないすべての権利を留保します。</p>`,
			section2: `<h2>サブスクリプション利用規約</h2>
<p>私たちのサブスクリプションサービス（以下「サービス」といいます）へようこそ。このサービスはHONG KONG ADFLASH MEDIA LIMITED（以下「当社」といいます）によって提供されます。サービスを利用またはサブスクライブすることで、これらのサブスクリプション利用規約（以下「規約」といいます）に従うことに同意します。</p>
<p>当社は、サブスクリプションベースのサービスを提供し、専用のユーザー枠を含みます。具体的なサービス内容および料金は随時変更される場合があり、その通知は電子メールまたは当社のウェブサイトを通じて行われます。</p>
<p>サブスクリプション料金、支払い方法、および支払いサイクルは料金ページに詳細が記載されています。支払いが滞納している場合、有料サービスは一時的に中断されます。</p>
<p>すべてのサブスクリプション料金は返金不可ですが、法的要件または当社の単独の裁量により返金される場合があります。</p>
<p>サブスクリプションはいつでもキャンセル可能です。キャンセル後も現在のサブスクリプション期間が終了するまでサービスを継続しますが、既に支払われた料金の返金は行いません。</p>
<p>また、以下の状況において、当社は一方的にサービスを終了または一時停止する権利を有します：</p>
<p>- これらの規約の違反</p>
<p>- サブスクリプション料金の未払い</p>
<p>当社は高品質なサービスを提供するために最善を尽くしますが、サービスの可用性、正確性、または完全性について、明示的または黙示的な保証を行いません。</p>`,
			section3: `<h2>他のユーザー</h2>
<p><strong>他のユーザー。</strong> 各サイトユーザーは、自身のユーザーコンテンツについて単独で責任を負います。私たちはユーザーコンテンツを管理していないため、あなたは私たちがあなたや他のユーザーが提供するユーザーコンテンツに責任を負わないことを認め、同意します。あなたは、これらのやり取りの結果として発生した損失または損害に対して、私たちは一切の責任を負わないことに同意します。あなたと他のサイトユーザー間で紛争が発生した場合、私たちは関与する義務を負いません。</p>
<p>あなたは、これにより、私たちおよび私たちの役員、従業員、代理人、後継者、および譲受人を、サイトから直接または間接的に生じた、またはサイトに関連する過去、現在、および未来のすべての紛争、請求、論争、要求、権利、義務、責任、行為および行動原因から解放し、永遠に免除することをここに同意します。あなたがカリフォルニア州の住民である場合、あなたはここに、前述の内容に関連してカリフォルニア民法第1542条を放棄します。この条文は、「一般的な解放は、解放を実行する時点で債権者に有利に存在することを知らなかったまたは疑わなかった請求には及ばないが、それが知られていれば債務者との和解に実質的な影響を与えたはずである」と規定しています。</p>
<p><strong>クッキーとウェブビーコン。</strong> 他のウェブサイトと同様に、私たちのウェブサイトも「クッキー」を使用します。これらのクッキーは、訪問者の嗜好や訪問したページなどの情報を保存するために使用されます。この情報は、訪問者のブラウザの種類やその他の情報に基づいて当社のウェブページの内容をカスタマイズすることで、ユーザー体験を最適化するために使用されます。</p>
<h2>免責事項</h2>
<p>サイトは「現状のまま」および「利用可能な状態で」提供されており、私たちおよび当社のサプライヤーは、明示的、黙示的、または法定のいかなる種類の保証および条件（商品性、特定の目的への適合性、所有権、静かな享受、正確性、または非侵害のすべての保証または条件を含む）を明示的に否認します。私たちおよび当社のサプライヤーは、サイトがあなたの要件を満たし、中断なく、適時に、安全に、エラーなく利用可能であり、正確で信頼性があり、ウイルスやその他の有害なコードが含まれておらず、完全で合法的かつ安全であることを保証しません。適用法がサイトに関連する保証を要求する場合、すべての保証は最初の使用日から九十（90）日に制限されます。</p>
<p>一部の法域では黙示的な保証の除外が許可されていないため、上記の除外はあなたに適用されない場合があります。また、一部の法域では黙示的な保証の期間の制限が許可されていないため、上記の制限はあなたに適用されない場合があります。</p>`,
			section4: `<h2>責任の制限</h2>
<p>法律で認められる最大限の範囲で、私たちまたは当社のサプライヤーは、これらの利用規約に関連するまたはこれから生じる、あるいはサイトの使用または使用不能に起因する、いかなる逸失利益、データの喪失、代替製品の調達費用、または間接的、結果的、模範的、偶発的、特別または懲罰的損害に対して、お客様または第三者に対して一切責任を負いません。これには、当社がそのような損害の可能性を通知された場合も含まれます。サイトへのアクセスおよび使用は自己の裁量とリスクに基づいて行われ、お客様のデバイスやコンピュータシステムへの損害、またはそこから生じるデータの喪失について、すべての責任を負うことになります。</p>
<p>法律で認められる最大限の範囲で、ここに含まれるいかなる矛盾する内容にもかかわらず、この契約に関連してまたはこれから生じる損害に対する当社の責任は、常に最大で五十米ドル（$50）に制限されます。複数の請求が存在する場合でも、この制限は拡大しません。私たちのサプライヤーは、この契約に関連していかなる種類の責任も負わないことに同意します。</p>
<p>一部の法域では偶発的または結果的損害に対する責任の制限または排除が認められていないため、上記の制限または排除はあなたに適用されない場合があります。</p>
<p><strong>契約期間と終了。</strong>本セクションに基づき、これらの利用規約はあなたがサイトを使用している間、完全に効力を持ちます。当社は、これらの規約に違反してサイトを使用する場合を含め、いつでも独自の裁量でサイトの使用権を一時停止または終了する権利を有します。これらの規約に基づくあなたの権利が終了すると、あなたのアカウントおよびサイトへのアクセス権と使用権が直ちに終了します。あなたのアカウントの終了には、ライブデータベースからあなたのアカウントに関連するユーザーコンテンツの削除が含まれる場合があることを理解します。当社は、これらの規約に基づくあなたの権利の終了に対して、いかなる責任も負いません。これらの規約に基づく権利が終了した後も、第2節から第2.5節、第3節および第4節から第10節は効力を持ち続けます。</p>
<p></p>
<p>補償。あなたは、サービスの使用に関連して、またはこれから生じるいかなる請求、損失、費用（弁護士費用を含む）に対しても、私たち、および当社の関連会社および従業員を防御し、補償し、損害から免除することに同意します。これには、あなたのコンテンツ、サービスと関連して開発または提供する製品やサービス、およびこれらの規約の違反または適用法の違反が含まれます。</p>
<p>免責事項。サービスは「現状のまま」提供されます。法律で禁止されている場合を除き、私たちおよび当社の関連会社やライセンサーは、サービスに関していかなる種類の保証も行わず、すべての保証（商品性、特定の目的への適合性、満足のいく品質、非侵害、安全な使用、および取引過程または商習慣から生じるいかなる保証も含む）を明示的に否認します。私たちは、サービスが中断されないこと、正確であること、エラーがないこと、またはいかなるコンテンツも安全であること、失われないこと、または改変されないことを保証しません。</p>`,
			section5: `<h2>著作権ポリシー</h2>
<p>私たちは他者の知的財産権を尊重し、サイトのユーザーにも同じようにすることを求めます。当サイトに関して、著作権法に基づき、侵害された資料の削除および著作権を含む知的財産権を繰り返し侵害するオンラインサイトのユーザーのアカウントの終了を行うポリシーを採用し、実施しています。もし、当サイトを利用しているユーザーがあなたの著作権を侵害していると信じ、侵害されているとされる資料を削除したい場合は、書面による通知（17 U.S.C. § 512(c) に基づく）に以下の情報を提供し、当社の指定著作権エージェントに提出する必要があります：</p>
<p>- 物理的または電子的な署名;</p>
<p>- あなたが侵害されたと主張する著作権作品の特定;</p>
<p>- あなたが侵害を主張し、削除を要求する当サービス上の資料の特定;</p>
<p>- 当社がその資料を特定できる十分な情報;</p>
<p>- あなたの住所、電話番号、および電子メールアドレス;</p>
<p>- 異議のある資料の使用が著作権所有者、その代理人、または法律により許可されていないと善意で信じている旨の声明;および</p>
<p>- 通知内の情報が正確であり、偽証罪の処罰の下で、あなたが侵害されたとされる著作権の所有者であるか、または著作権所有者を代表して行動する権限があることを証明する声明。</p>
<p>17 U.S.C. § 512(f) に基づき、書面通知における重要な事実の虚偽表示は、自動的に苦情を申し立てた当事者に対して、当社が書面通知および著作権侵害の申し立てに関連して被った損害、費用、および弁護士費用の一切の責任を負うことに注意してください。</p>`,
			section6: `<h2>一般規定</h2>
<p>これらの規約は時折改訂されることがあり、重要な変更を行った場合には、最後に提供された電子メールアドレスにメールを送信することで、または当サイトに変更の通知を目立つ形で掲載することで通知する場合があります。現在のメールアドレスを提供することはあなたの責任です。あなたが最後に提供したメールアドレスが無効である場合でも、当社がその通知を含むメールを送信した場合には、その通知は有効な通知とみなされるものとします。これらの規約の変更は、当社がメール通知を送信してから30暦日後、または当社のサイトに変更の通知を掲載してから30暦日後のいずれか早い方に有効となります。これらの変更は、新しいユーザーには直ちに有効となります。このような変更の通知後にサイトを引き続き使用することで、あなたはその変更を承認し、変更された利用規約に拘束されることに同意したものとみなされます。<strong>紛争解決</strong> この仲裁合意を注意深く読んでください。これはあなたと私たちとの契約の一部であり、あなたの権利に影響を与えます。これは、<strong>強制的な拘束力を持つ仲裁および集団訴訟の放棄</strong>の手続きが含まれています。</p>
<p><strong>仲裁合意の適用性</strong> 規約または当社が提供する製品またはサービスの使用に関連して解決できないすべての請求および紛争は、この仲裁合意の条件に基づいて個別に拘束力のある仲裁によって解決されるものとします。特に合意しない限り、すべての仲裁手続きは英語で行われます。この仲裁合意は、あなたと当社、およびその子会社、関連会社、代理人、従業員、前任者、後継者、および譲受人、ならびに規約に基づいて提供されるサービスまたは商品を使用するすべての認可または非認可のユーザーまたは受益者に適用されます。</p>
<p><strong>通知要件および非公式の紛争解決</strong>いずれの当事者も仲裁を求める前に、まず相手方に対して請求または紛争の性質および根拠、および求める救済を記載した書面による紛争通知を送る必要があります。通知は当社の連絡先メールに送る必要があります。通知を受け取った後、あなたと当社は請求または紛争を非公式に解決しようと試みることができます。通知を受け取ってから30日以内に請求または紛争を解決しない場合、いずれの当事者も仲裁手続きを開始することができます。いずれかの当事者が提案した示談金額は、仲裁人がどちらの当事者に対しても授与される金額を決定するまで公開されません。</p>
<p><strong>仲裁規則</strong>: 仲裁は、ここに示されているように仲裁を提供する定評ある代替紛争解決プロバイダーであるアメリカ仲裁協会（AAA）を通じて開始されます。AAAが仲裁に利用できない場合、当事者は代替のADRプロバイダーを選択することに合意します。ADRプロバイダーの規則は、規約と矛盾しない限り、仲裁のすべての側面を支配します。仲裁を支配するAAA消費者仲裁規則は、adr.orgオンラインで、または1-800-778-7879に電話してAAAに連絡することで入手できます。仲裁は、単一の中立仲裁人によって行われます。求める賞金の総額が一万米ドル（$10,000.00）未満の請求または紛争は、救済を求める当事者の選択により、非出席型の拘束力のある仲裁を通じて解決される場合があります。求める賞金の総額が一万米ドル（$10,000.00）以上の請求または紛争については、審理の権利は仲裁規則によって決定されます。いかなる審理も、当事者が別途合意しない限り、あなたの居住地から100マイル以内の場所で行われます。あなたが米国外に居住している場合、仲裁人は口頭審理の日付、時間、および場所について当事者に合理的な通知を行います。仲裁人が下した賞金に関するいかなる判決も、管轄裁判所で執行されます。仲裁人があなたに仲裁を開始する前にWeがした最後の和解案を上回る賞金を授与した場合、Weは賞金または2,500米ドルのいずれか高い方を支払います。各当事者は仲裁から生じる費用および支出を自ら負担し、ADRプロバイダーの手数料および費用を平等に分担します。</p>
<p><strong>非出席型仲裁のための追加規則</strong>: 非出席型仲裁が選ばれた場合、仲裁は電話、オンライン、または書面による提出のみに基づいて行われます。特定の方法は仲裁を開始する当事者によって選ばれます。当事者または証人の個人的な出席を伴うことはありません、ただし当事者が別途合意した場合を除きます。</p>
<p><strong>時効</strong>: あなたまたはWeが仲裁を追求する場合、仲裁活動は時効内およびAAA規則に基づいて課された期限内に開始および/または要求されなければなりません。</p>
<p><strong>仲裁人の権限</strong> 仲裁が開始された場合、仲裁人はあなたとWeの権利および責任を決定し、紛争は他の事項と統合されることなく、他のケースや当事者と結合されることはありません。仲裁人は、いかなる請求の全部または一部に対する動議を認める権限を有します。仲裁人は、金銭的損害を授与する権限を有し、適用法、AAA規則、および規約に基づいて個人に提供されるいかなる非金銭的な救済または救済措置も認める権限を有します。仲裁人は、裁定の基礎となる重要な発見および結論を記述した書面による裁定と決定書を提出します。仲裁人は、裁判所の判事と同じ権限を持ち、個人に対して救済を授与することができます。仲裁人の裁定は最終的なものであり、あなたとWeに対して拘束力があります。</p>
<p><strong>陪審裁判の権利放棄</strong> 当事者はここに裁判所に行き、判事または陪審による裁判を受ける憲法上および法定の権利を放棄し、代わりにすべての請求および紛争がこの仲裁合意に基づいて仲裁によって解決されることを選択します。仲裁手続きは通常、裁判所で適用される規則よりも制限され、効率的で安価であり、裁判所によるレビューも非常に限定的です。あなたとWeの間で裁定を無効にする訴訟や裁定を強制する訴訟が州や連邦の裁判所で発生した場合、あなたとWeはすべての陪審裁判の権利を放棄し、代わりに判事によって紛争が解決されることを選択します。</p>
<p><strong>集団または統合訴訟の放棄</strong> この仲裁合意の範囲内のすべての請求および紛争は、個別に仲裁または訴訟されなければならず、集団として仲裁または訴訟されることはなく、複数の顧客やユーザーの請求は他の顧客やユーザーの請求と共同または統合されることはありません。</p>
<p><strong>機密性</strong> 仲裁手続きのすべての側面は厳密に機密とされます。当事者は、法律で要求されない限り、機密性を維持することに同意します。この段落は、この契約を強制するため、仲裁裁定を強制するため、または差止めや衡平法上の救済を求めるために必要な情報を裁判所に提出することを妨げるものではありません。</p>`,
			section7: `<p><strong>分離可能性</strong> この仲裁合意のいかなる部分または部分が、管轄裁判所によって法律の下で無効または執行不可能と判断された場合、その特定の部分または部分は効力を持たず、分離され、残りの合意は引き続き完全な効力を保持します。</p>
<p><strong>放棄の権利</strong> この仲裁合意に定められた権利および制限の全部またはいくつかは、請求に対して主張されている当事者によって放棄されることがあります。そのような放棄は、この仲裁合意の他の部分を放棄または影響するものではありません。</p>
<p><strong>合意の存続</strong> この仲裁合意は、あなたとWeの関係が終了した後も存続します。</p>
<p><strong>少額訴訟裁判所</strong> 沿革のいかんにかかわらず、あなたまたはWeは、少額訴訟裁判所に個別の訴訟を提起することができます。</p>
<p><strong>緊急衡平救済</strong> 沿革のいかんにかかわらず、いずれの当事者も、仲裁を待つ間、現状を維持するために州または連邦裁判所で緊急衡平救済を求めることができます。仮の措置の要求は、この仲裁合意に基づく他の権利または義務の放棄とはみなされません。</p>
<p><strong>仲裁の対象外の請求</strong> 沿革のいかんにかかわらず、名誉毀損、コンピュータ詐欺および不正使用法の違反、ならびに他の当事者の特許、著作権、商標または営業秘密の侵害または不正流用の請求は、この仲裁合意の対象外となります。</p>
<p>前記の仲裁合意が当事者に裁判所での訴訟を許可する状況において、当事者はカリフォルニア州のネザーランズ郡内に所在する裁判所の個別管轄権に従うことに同意します。</p>
<p>サイトは米国の輸出管理法の対象となる場合があり、他の国の輸出または輸入規制も適用される場合があります。あなたは、Weから取得した米国の技術データや、そのようなデータを使用する製品を、米国の輸出法や規制に違反して直接または間接的に輸出、再輸出、または転送しないことに同意します。</p>`,
			section8: `<p><strong>電子通信</strong>: あなたとWeの間の通信は、あなたがサイトを使用するか、私たちにメールを送るか、Weがサイトに通知を投稿するか、またはメールであなたと通信するかにかかわらず、電子手段を使用します。契約上の目的のために、あなたは (a) Weから電子形式での通信を受け取ることに同意し、(b) Weが電子的に提供するすべての規約、契約、通知、開示、およびその他の通信が、物理的な書面で行われた場合に満たされる法的義務を満たすことに同意します。</p>
<p><strong>全体の契約</strong>: これらの規約は、サイトの使用に関してあなたと私たちの間の完全な合意を構成します。私たちがこれらの規約のいかなる権利または条項を行使または執行しない場合でも、その権利または条項の放棄とはなりません。これらの規約のセクションタイトルは便宜上のものであり、法的または契約上の効果はありません。「含む」という言葉は「制限なく含む」の意味です。これらの規約のいかなる条項が無効または執行不可能とされた場合でも、他の条項は損なわれず、無効または執行不可能な条項は、法律で許可される最大限の範囲で有効かつ執行可能となるように修正されたものとみなされます。あなたとWeの関係は独立請負業者のものであり、いずれの当事者も他方の代理人またはパートナーではありません。これらの規約およびここに記載されたあなたの権利と義務は、Weの事前の書面による同意なしにあなたによって譲渡、下請、委任、またはその他の方法で移転することはできず、そのような譲渡、下請、委任、または移転の試みは無効とみなされます。Weはこれらの規約を自由に譲渡することができます。これらの規約に記載された条件は、譲受人に対して拘束力があります。</p>
<p><strong>あなたのプライバシー</strong>: プライバシーポリシーをお読みください。</p>
<p><strong>著作権/商標情報</strong>: Copyright ©. All rights reserved. サイトに表示されるすべての商標、ロゴ、およびサービスマークは、私たちまたは他の第三者の財産です。これらのマークを無断で使用することはできません。私たちまたはそのマークの所有者である第三者の事前の書面による同意が必要です。</p>
<h2>連絡先情報</h2>
<p>Email: watch_support@adflash.cn</p>`
		},
		privacy: {
			section1: `<p>あなたのプライバシーを保護することは私たちにとって重要です。以下の声明が、インターネットを通じて提供することがある個人識別情報をどのように取り扱うかを理解するのに役立つことを願っています。</p>
<p>当社のアプリおよび関連サービスに基づいて、第三者が提供する特別な機能サービスの利用を申し込むことができます。特別な機能サービスを登録し使用する必要がある場合、その特別な機能サービスの利用規約を注意深くお読みください。いかなる特別な機能サービスを受け入れるか使用することにより、あなたはこの契約および特別な機能サービスの利用規約を読み、受け入れ、それに拘束されることに同意したことを認めます。</p>
<p>私たちは、あなたが求めるサービスを提供するために情報が必要な場合があります。このプライバシーステートメントは、当社の製品に適用されます。</p>
<h2>チームが収集し受け取る情報</h2>
<p>ソフトウェアおよびサービスを利用する際に、私たちは以下の種類の情報を自動的に収集し保存する場合があります：</p>
<p>1. 技術情報。あなたのデバイスに関する特定の技術情報、およびあなたのソフトウェアおよびサービスと他のサードパーティ製ソフトウェアやアプリケーション（「他のソフトウェア」）の使用に関する情報（オペレーティングシステムおよび他のソフトウェアの種類、名前およびバージョン、それらとソフトウェアおよびサービスとの相互作用、ISP（インターネットサービスプロバイダ）、位置情報（GPS）、IPアドレス、匿名ユーザー識別子、デバイス構成および識別子（iOSで使用される広告用識別子（IDFA）、Androidで使用される広告用識別子（Advertising ID）など）、ソフトウェアおよびサービスのバージョン、ならびにソフトウェアおよびサービスの構成および設定を含む）；</p>
<p>2. 使用情報。デバイス、ソフトウェアおよびサービス、および他のソフトウェアの使用および相互作用に関する特定の使用に関する情報（例：ソフトウェアおよびサービスおよび他のソフトウェアを使用する時期および方法、インストールされているアプリケーション、使用頻度、広告の表示、使用およびアクセス、広告の使用方法およびそれに対する反応）；</p>
<p>3. ソーシャルネットワーク。ソフトウェアおよびサービスを通じたソーシャルネットワークでのやり取り；</p>`,
			section2: `<p>4. 登録に関連する情報。ソフトウェアおよびサービスに登録する際に提供する情報（該当する場合）、例：名前、自宅または職場の住所、メールアドレス、電話番号およびファックス番号、誕生日および性別；</p>
<p>5. サポートに関連する情報。当社のサポートセクションまたはメールを通じて連絡を取る場合、我々はあなたのメールアドレス、名前、およびサポートに関連して自発的に提出された情報を収集する場合があります。ソフトウェアおよびサービスを使用する際、Weは自動的に匿名の内部ユーザーIDを生成し、これはソフトウェアおよびサービスの使用に割り当てられ（「ユーザーID」）、上記のいずれかの情報と組み合わされる場合があります。</p>
<p>6. デバイス情報</p>
<p>デバイスのハードウェアモデル、オペレーティングシステムとバージョン、一意のデバイス識別子（SSAID、GAIDを含む）、ブラウザの種類と言語、モバイルデバイスの電話番号、モバイルネットワーク情報、国際モバイル装置識別番号（IMEI）、広告用識別子（IDFA）、ベンダー識別子（IDFV）、集積回路カード識別子（ICCID）、メディアアクセスコントロール（MAC）アドレス、モデルおよび解像度などに関する情報を収集します。これらは、当社の製品およびサービスを使用するデバイスの数を計算し、デバイスモデルおよびグラフィック適応に関するデータを分析するために使用されます。特定の情報を提供しないことを選択できますが、そうした場合、多くのアプリを利用することができなくなる場合があります。また、サービスの使用中にクラッシュやその他の問題が発生した場合（願わくば稀であることを願います）、問題を診断するのに役立つ特定のデバイス情報も収集します。</p>
<p>7. カメラと写真</p>
<p>多くのサービスでは、デバイスのカメラや写真から画像やその他の情報を収集する必要があります。あなたの同意を得た後にのみ、カメラや写真にアクセスします。提供しないことを選択できますが、その場合、多くのサービスを利用できなくなる可能性があります。</p>
<p>8. ローカルストレージ</p>
<p>ブラウザのウェブストレージ（HTML5を含む）やアプリケーションデータキャッシュなどのメカニズムを使用して、デバイス上に情報をローカルに収集および保存する場合があります。保存する情報は第三者と共有されることはありません。</p>
<p>9. 非個人情報</p>`,
			section3: `<p>非個人情報とは、Android ID、CPNモデル、メモリサイズ、電話のIMEI番号、電話モデル、ROM、インストールされたアプリケーションの名前とパッケージ名、電話のオペレータ、位置情報、インストール、アンインストール、使用頻度など、特定の個人を直接関連付けることができない形式のデータです。</p>
<p>以下の状況で非個人情報を収集および使用する場合があります。ユーザーの行動をより良く理解し、製品およびサービスの問題を解決し、製品、サービスおよび広告を改善するために、インストールされたアプリケーションの名前とパッケージ名、インストールデータ、使用頻度、国、機器およびチャネルなどの非個人情報を収集する場合があります。</p>
<h2>チームがあなたの情報をどのように使用するか？</h2>
<p>チームは、次の方法で分析、ビジネス、および運用目的のために収集した情報を使用する場合があります：</p>
<p>1. ソフトウェアおよびサービスを運営および更新し、既存および新しい機能や特徴を提供するため；</p>
<p>2. ソフトウェアおよびサービスを改善およびカスタマイズするため；</p>
<p>3. あなたにパーソナライズされたコンテンツを提供するため、我々またはパートナーからのターゲット広告を含む；</p>
<p>4. ソフトウェアおよびサービスの品質と運営を維持および改善するため；</p>
<p>5. ソフトウェアおよびサービスの更新を通知するため；</p>
<p>6. サポートを提供し、苦情やフィードバックを処理するため；</p>
<p>7. ソフトウェアおよびサービスに関連してあなたが関与する紛争が発生した場合の措置を取るため；</p>`,
			section4: `<p>8. ソフトウェアおよびサービスに適用される規約を執行し、不法行為およびその乱用を防止するため；</p>
<p>9. 適用される法規、法的手続き、または執行可能な政府の要求を遵守および満たすため。第三者との情報共有。</p>
<h2>子供のプライバシー</h2>
<p>これらのサービスは13歳未満の者を対象としていません。私は13歳未満の子供から個人識別情報を意図的に収集しません。13歳未満の子供が個人情報を提供したことに気付いた場合、すぐにこれをサーバーから削除します。あなたが親または保護者であり、あなたの子供が私たちに個人情報を提供したことに気付いた場合は、必要な措置を取ることができるように私に連絡してください。</p>
<h2>このプライバシーポリシーの変更</h2>
<p>私は時折プライバシーポリシーを更新することがあります。そのため、定期的にこのページを確認することをお勧めします。新しいプライバシーポリシーをこのページに掲載することで、変更を通知します。これらの変更は、このページに掲載された直後に有効となります。</p>
<h2>お問い合わせ</h2>
<p>私のプライバシーポリシーに関して質問や提案がある場合は、遠慮なく私に連絡してください。Email: watch_support@adflash.cn</p>`,


		},
		refund: {
			section1: `<p>ご購入いただきありがとうございます。購入に完全に満足していない場合、お手伝いさせていただきます。</p>
<p>返金プロセスを開始するには、以下の手順に従ってください。</p>
<p>サブスクリプション製品（例: ベーシックサブスクリプション）</p>
<p>利用を停止したがサブスクリプションをキャンセルし忘れた場合、お支払い後にセッションを利用しておらず、画像を作成していない限り、通常は最新のサブスクリプション支払いを返金いたします。</p>
<p>返金を依頼するには、アカウントおよびフィードバックフォームを通じて返金リクエストを送信してください。本人確認のために特定の情報をお尋ねする場合があります。</p>
<p>デジタル製品（例: タイムパック、サブスクリプション）</p>
<p>タイムパックやサブスクリプションが使用された場合、返金は行いません。部分的または比例的な返金は提供しません。</p>
<p>取引日から14暦日以内に返金を依頼してください。</p>
<p>返金リクエストを受け取ると、当社のスタッフが会員ステータスをチェックし、返金の資格があるかどうかを判断します。</p>`,
			section2: `<p>承認された場合、返金処理が行われます。返金は購入時に使用された元の[支払い方法]にクレジットされます。</p>
<p>返金の遅延または未達</p>
<p>返金を受け取っていない場合、まずは銀行口座を再確認してください。</p>
<p>次にクレジットカード会社に連絡してください。返金が公式にリリースされるまで時間がかかる場合があります。</p>
<p>その次に銀行に連絡してください。通常、返金がリリースされるまでに処理時間がかかります。</p>
<p>これらすべてを行ってもまだ返金を受け取っていない場合は、watch_support@adflash.cnまでご連絡ください。</p>
<p>セール品</p>
<p>返金は正規価格の商品にのみ適用され、セール品は返金不可です。</p>`,
		},
		us: {
			section1: `<p>会社情報</p>
<p>名前: HONG KONG ADFLASH MEDIA LIMITED</p>
<p>住所: RM 1101 11/F SAN TOI BUILDING NO.139 CONNAUGHT ROAD CENTRAL HK</p>
<p>Email: watch_support@adflash.cn</p>`,
		},
	}
}