// import { watch } from 'vue';
import state from './state/test';

export default {

  ChatSelect: (state) => state.ChatSelect,
  DiscoverSelect: (state) => state.DiscoverSelect,
  getOs:(state)=> {
    return state.os;
},
getDeviceId:(state)=> {
    return state.deviceId;
},
getAppPkgName:(state)=> {
    return state.appPkgName;
},
getAppVersion:(state)=> {
    return state.appVersion;
},
getSdkVersion:(state)=> {
    return state.sdkVersion;
},
getChannelId:(state)=> {
    return state.channelId;
},
getSdkChannelId:(state)=> {
    return state.sdkChannelId;
},
getToken:(state)=> {
    return state.token;
},
getBind:(state)=> {
    return state.bind;
},
getWatch:(state)=> {
    return state.watch;
},
getWatchId:(state) => {
    return state.watchId;
},
getLanguage:(state) => {
    return state.language;
},
// 通知
getNotificationId:(state) => {
    return state.notificationId;
},
getNOtificationType:(state) => {
    return state.notificationType;
},

// 参数解析
getParams:(state)=> {
    return {
        os: state.os ,
        osVersion: state.osVersion,
        deviceId: state.deviceId ,
        appPkgName: state.appPkgName ,
        appVersion: state.appVersion ,
        sdkVersion: state.sdkVersion ,
        channelId: state.channelId ,
        sdkChannelId: state.sdkChannelId ,
        token: state.token ,
        bind: state.bind ,
        notificationId: state.notificationId ,
        notificationType: state.notificationType,
        language: state.language ,
        watch: state.watch ,
        watchId: state.watchId,
    }
},


// 参数映射
getPP:(state)=> {
    return state.pP;
},
getPOs:(state)=> {
    return state.pOs;
},
getPOsVersion:(state)=> {
    return state.pOsVersion;
},
getPDeviceId:(state)=> {
    return state.pDeviceId;
},
getPAppPkgName:(state)=> {
    return state.pAppPkgName;
},
getPAppVersion:(state)=> {
    return state.pAppVersion;
},
getPSdkVersion:(state)=> {
    return state.pSdkVersion;
},
getPChannelId:(state)=> {
    return state.pChannelId;
},
getPSdkChannelId:(state)=> {
    return state.pSdkChannelId;
},
getPToken:(state)=> {
    return state.pToken;
},
getPBind:(state)=> {
    return state.pBind;
},
getPNotificationId:(state)=> {
    return state.pNotificationId;
},
getPNotificationType:(state)=> {
    return state.pNotificationType;
},
getPLanguage:(state)=> {
    return state.pLanguage;
},
getPWatch:(state)=> {
    return state.pWatch;
},
getPWatchId:(state)=> {
    return state.pWatchId;
},




getObj:(state)=> {
    return state.obj;
},
getType:(state)=> {
    return state.type;
},
getSubType:(state)=> {
    return state.subType;
},
getMsg:(state)=> {
    return state.msg;
},


getAudio:(state)=> {
    return state.audio;
},
getImage:(state)=> {
    return state.image;
},


getPermission:(state)=> {
    return state.permission;
},
getSaveImage:(state)=> {
    return state.saveImage;
},
getStartRecordAudio:(state)=> {
    return state.startRecordAudio;
},
getCancelRecordAudio:(state)=> {
    return state.cancelRecordAudio;
},
getFinishRecordAudio:(state)=> {
    return state.finishRecordAudio;
},
getMode:(state)=> {
    return state.mode;
},
getContent:(state)=> {
    return state.content;
},

// 交互
getOsResult:(state)=> {
    return state.osResult;
},

// sdk 交互  关键词
getInteraction:(state)=> {
    return {
        obj: state.obj,
        type: state.type,
        subType: state.subType,
        msg: state.msg,
        audio: state.audio,
        image: state.image,
        permission: state.permission,
        saveImage: state.saveImage,
        startRecordAudio: state.startRecordAudio,
        cancelRecordAudio: state.cancelRecordAudio,
        finishRecordAudio: state.finishRecordAudio,
        mode: state.mode,
        content: state.content,
        lifeCycle:state.lifeCycle,
        backOut:state.backOut,
        backStage:state.backStage
    }
},

// 状态

getWatchList:(state)=> {
    return state.watchList;
},
getTotalNumber:(state)=> {
    return state.totalNumber;
}

}