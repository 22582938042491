export default {
  setsubscription(state, val) {
    state.subscription = val;
  },
  setwatchdate(state, val) {
    state.watchdate = val;
  },
  setChatSelected(state, val) {
    state.ChatSelect = val;
    // localStorage.setItem("ChatSelect", val); // 每次状态变化时保存到本地存储
  },
  setDiscoverSelected(state, val) {
    state.DiscoverSelect = val;
  },
  setFontSize(state,val){
    state.fontSize = val;
    localStorage.setItem('fontSetting', val);
  
  },
  setOpenSound(state,val){
    state.showSound = val;
    localStorage.setItem('OpenSound', val);
  },
  setInit(state,val){
    state.isInit = val;
    localStorage.setItem('isInit', val);
  },
  setMyCreation(state,val){
    state.myCreation = val;
    localStorage.setItem('myCreation', JSON.stringify(val));
  },
  SET_OS:(state, os)=> {
    state.os = os;
},
SET_OS_VERSION:(state, osVersion)=> {
    state.osVersion = osVersion;
},
SET_DEVICE_ID:(state, deviceId)=> {
    state.deviceId = deviceId;
},
SET_APP_PKG_NAME:(state, appPkgName)=> {
    state.appPkgName = appPkgName;
},
SET_APP_VERSION:(state, appVersion)=> {
    state.appVersion = appVersion;
},
SET_SDK_VERSION:(state, sdkVersion)=> {
    state.sdkVersion = sdkVersion;
},
SET_CHANNEL_ID:(state, channelId)=> {
    state.channelId = channelId;
},
SET_SDK_CHANNEL_ID:(state, sdkChannelId)=> {
    state.sdkChannelId = sdkChannelId;
},
SET_TOKEN:(state, token)=> {
    state.token = token;
},
SET_BIND:(state, bind)=> {
    state.bind = bind;
},
SET_NOTIFICATION_ID:(state, notificationId)=> {
    state.notificationId = notificationId;
},
SET_NOTIFICATION_TYPE:(state, notificationType)=> {
    state.notificationType = notificationType;
},
SET_LANGUAGE:(state, language)=> {
    state.language = language;
},
// 新增
SET_WATCH:(state, watch)=> {
    state.watch = watch;
},
SET_WATCH_ID:(state, watchId)=> {
    state.watchId = watchId;
},

// 状态

SET_WATCH_LIST:(state, watchList)=> {
    state.watchList = watchList;
},
SET_TOTAL_NUMBER:(state, totalNumber)=> {
    state.totalNumber = totalNumber;
},
SUB_TOTAL_NUMBER:(state)=> {
    if(state.totalNumber > 0) {
        state.totalNumber -= 1;
    } else {
        state.totalNumber = 0;
    }
    
}
}