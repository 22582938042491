module.exports = {
	language: "繁体中文",
	navigator: {
		message1: "聊天",
		message2: "智能體",
		message3: "我的",
	},
	chat: {
		adjustment: {
			message1: "調整",
			message2: "幫我預覽字體大小",
			message3: "您可以通過拖動滑塊來調整字體大小。設置後，對話中的字體大小將會改變。",

		},
		aiFriendPages: {
			message1: "嗨，我是你的新朋友！很高興第一次見到你。我可以回答你的所有問題，幫助你完成工作和學習，隨時與你聊天。那麼，你首先想問什麼？",
			message2: "提問",
			message3: "由於內容是由大模型生成的，",
			message4: "真實性無法完全保證。",
			message5: "官方",
		},
		downloadImg: {
			message1: "下載",
			message2: "成功保存到相冊",
			message3: "",
			message4: "",
			message5: "",
		},
		searchPage: {
			message1: "加載中",
			message2: "目前無搜索結果",
			message3: "",
			message4: "",
			message5: "",
		},
		smartAiInfo: {
			message1: "字體大小調整",
			message2: "清除聊天記錄",
			message3: "是否清除當前聊天記錄",
			message4: "取消",
			message5: "確認",
		},
		smartAiPages: {
			message1: "圖片生成中",
			message2: "單擊任意處或倒計時結束時識別",
			message3: "點擊說話",
			message4: "內容由 AI 生成，僅供參考。",
			message5: "智能代理已被刪除",
		},
		smartAiPagesDEV: {
			message1: "圖片生成中",
			message2: "單擊任意處或倒計時結束時識別",
			message3: "點擊說話",
			message4: "內容由 AI 生成，僅供參考。",
			message5: "智能代理已被刪除",
			message6:"該智能體不支援文件和圖片發送",
			message7:"該智能體不支援文件發送",
			message8:"目前對話進行中",
			message9:"AI繪製提示詞違反規定，請輸入符合規定的提示詞。",
			message10:"該圖片違反規定，無法辨識。",
			message11:" 此對話已達到限制，請開始新的 Al 聊天會話",
			message12:" 新對話",
			message13:"Al 服务器繁忙，请重试。本次使用不消耗您的积分。",
			message14:"问答次数已使用完毕，请去手表端，扫描二维码即可继续使用。",
		},
		smartAiReadonly: {
			message1: "官方",
			message2: "嗨，我是你的新朋友！很高興第一次見到你。我可以回答你的所有問題，幫助你完成工作和學習，隨時與你聊天。那麼，你首先想問什麼？",
			message3: "您可以通過拖動滑塊來調整字體大小。設置後，對話中的字體大小將會改變。",
			message4: "嗨，我是你的新朋友！很高興第一次見到你。我可以回答你的所有問題，幫助你完成工作和學習，隨時與你聊天。那麼，你首先想問什麼？",
			message5: "",
		},
		watchPages: {
			message1: "手錶1",
			message2: "官方",
			message3: "我在這裡。我能幫你什麼嗎",
			message4: "刪除智能體",
			message5: "刪除聊天記錄",
		},
		index: {
			message1: ["推薦", "學習", "生活", "創作", "繪畫", "工作"],
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	components: {
		AIFriend: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		chatAI: {
			message1: "你還沒有創建任何對話",
			message2: "手錶 0",
			message3: "取消收藏",
			message4: "確認將智能體添加到 手表名稱？",
			message5: "取消",
			message6: "確認",
		},
		MyAI: {
			message1: "對不起。你還沒有收藏任何東西",
			message2: "刪除",
			message3: "取消收藏",
			message4: "確認將 AI 藝術添加到此手錶？",
			message5: "刪除人工智能植物學家",
			message6: "取消",
			message7: "確認",
		},
		SmartAI: {
			message1: "對不起。你還沒有收藏任何東西",
			message2: "刪除",
			message3: "取消收藏",
			message4: "確認將智能體添加到",
			message5: "刪除智能體",
			message6: "取消",
			message7: "確認",
		},
		SmartAI2: {
			message1: "對不起。你還沒有收藏任何東西",
			message2: "刪除智能體",
			message3: "取消收藏",
			message4: "確認將智能體添加到",
			message5: "刪除智能體",
			message6: "取消",
			message7: "確認",
		},
		Watch: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	create: {
		addSA: {
			message1: "創建",
			message2: "添加頭像",
			message3: "名稱",
			message4: "設置描述",
			message5: "語言",
			message6: "聲音",
			message7: "創建",
			message8: "AI 生成",
			message9: "相冊上傳",
			message10: "取消",
			message11: "例子：你是一位經驗豐富的英語老師，擁有激發學生學習的教學方法。你使用幽默和現實生活中的例子讓對話變得有趣。",
			message12: "此圖片將用於 AI 頭像",
		},
		aiGeneration: {
			message1: "AI 生成",
			message2: "添加參考圖片（可選）",
			message3: "上傳一張面部清晰的照片以幫助生成圖像",
			message4: "名稱",
			message5: "創建",
			message6: "添加圖片描述",
		},
		createSA: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		selectLanguage: {
			message1: "語言",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		selectSound: {
			message1: "聲音",
			message2: "目前未選擇任何聲音",
			message3: "柔和蜜桃",
			message4: "刪除智能體",
			message5: "",
		},
		index: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	devePage: {

	},
	discover: {

	},
	support: {
		message1: "智能體",
		message2: "我的創作",
		message3: "開放",
		message4: "人人都可以說話",
		message5: "私人",
		message6: "只有你可以說話",
		message7: "輸入名字",
		message8: "搜尋AI",
		message9: "發送訊息 ",
		message10: "標準",
	},
	mine: {
		mineWatchPages: {
			message1: "刪除 智能體",
			message2: "您確定要從您的手錶中刪除智能體嗎？",
			message3: "取消",
			message4: "主頁已滿",
			message5: "您的手錶主頁已達到智能體的限額。請在添加新智能體之前移除一些。",
			message6: "編輯名稱",
			message7: "確認",
			message8: "我的手錶",
			message9: "iMei",
			message10: "手錶智能體",
			message11: "手錶設置",
			message12: "添加到手錶主頁",
			message13: "Al 內容評級",
			message14: "從手錶主頁移除",
		},
		settingindex: {
			message1: "設置",
			message2: "AI模式",
			message3: "語言",
			message4: "訂單記錄",
			message5: "問題反饋",
			message6: "通知",
			message7: "服務條款",
			message8: "隱私政策",
			message9: "打開失敗",
			message10: "訂單記錄無法打開。請掃描手錶上的二維碼來打開。",
			message11: "確認",
		},
		Language: {
			message1: "語言",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		MySubscription: {
			message1: "模式選擇智能體",
			message2: "專業版",
			message3: "標準版",
			message4: "",
			message5: "",
		},
		index: {
			message1: "我的手錶",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	points: {},
	smartai: {
		collectFriendlyAI: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collectInspiration: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collectSmartAi: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		collect: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		discoverAF: {
			message1: ["我的", "精選", "女性", "男性", "動漫", "遊戲"],
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		discoverSA: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		inspiration: {
			message1: "",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
		mycreation: {
			message1: "創建我的智能體",
			message2: "",
			message3: "",
			message4: "",
			message5: "",
		},
	},
	Toast: {
		message1: "Jarvis",
		message2: "複製成功",
		message3: "此智能體不支持發送文件和圖片",
		message4: "當前對話正在進行中",
		message5: "名必須至少包含 4 個字符",
		message6: "內容應至少包含 4 個字符",
		message7: "成功",
		message8: "錯誤",
		message9: "名稱",
		message10: "提示",
		message11: "隱私",
		message12: "語言",
		message13: "ai 聲音代碼",
		message14: "圖片",
		message15: "空",
		message16: "智能體正在移動",
		message17: "添加成功",
		message18: "移除成功",
		message19: "添加智能體失敗",
		message20: "網絡連接不穩定。請重試。",
		message21: "智能體添加完成",
		message22: "刪除智能體失敗",
		message23: "網絡連接不穩定。請重新添加智能體。",
		message24: "刪除人工智能植物學家",
		message25: "智能體刪除完成",
		message26: "刪除智能體失敗，網絡連接不穩定。請重新添加智能體",
		message27: "此智能體不支持發送文件和",
		message27: "複製失敗，請手動選擇複製！",
		message28: "創建中",
		message29: "值為空",
		message30: "智能體正在刪除",
		message31: "智能體正在添加",
		message32: "智能體正在刪除",
		message33: "普通問答次數已使用完畢。",
		message34: "高級問答次數已使用完畢。",
	},
	setting: {
		index: {
			title: "設定",
			problem: "問題回饋",
			order: "訂單記錄",
			notice: "通知",
			language: "語言",
			clear: "清除緩存",
			clearTip: "您是否要確認\n清除緩存？",
			confirm: "確認",
			cancel: "取消",
		},
		language: {
			title: "語言",
			langList: ["簡體中文", "繁體中文", "英語", "阿拉伯語", "波蘭語", "波斯語", "丹麥語", "德文", "俄文", "法語", "荷蘭語", "捷克語", "羅馬尼亞語",
				"葡萄牙語", "日語", "泰語", "土耳其語", "西班牙語", "希臘文", "義大利語", "越南語", "印地語"
			],
		},
		order: {
			title: "訂單記錄",
			success: "獲取成功",
			unpaid: "未支付",
			paid: "已支付",
			expired: "已過期",
			refunded: "已退款",
			pTime: "支付時間",
			eTime: "到期時間",
			pMethod: "支付方式",
			pObject: "支付對象",
			oNumber: "訂單號碼",
			pType: "套餐類型",
			oTime: "獲取時間",
			watch: "手錶 IMEI",
			delete: "刪除訂單",
			refund: "申請退款",
		},
		problem: {
			title: "問題反饋",
			feedback: "反饋類型",
			please: "請選擇",
			content: "內容",
			improve: "請寫下您的建議，如功能需求、產品投訴等。我們將努力改進",
			upload: "上傳圖片",
			submit: "提交",
			general: "一般反饋",
			purchase: "購買相關",
			bug: "錯誤報告",
			suggestion: "建議",
			other: "其他",
		},
		notice: {
			title: "通知",
			type: "通知類型：",
			time: "通知時間："
		}
	},
	policy: {
		title: {
			terms: "條款",
			about: "關於",
			service: "服務條款",
			privacy: "隱私政策",
			refund: "退款政策",
			us: "關於我們",
		},
		service: {
			section1: `<p>位於 https://aiwatchstore.org 的網站是屬於香港 ADFLASH MEDIA LIMITED 的版權作品。網站的某些功能可能會受到其他指南、條款或規則的約束，這些內容將與相應功能一起在網站上發布。</p>
<p>所有此類其他條款、指南和規則均通過引用納入這些條款之中。</p>
<p>這些使用條款描述了監管您使用本網站的具有法律約束力的條款和條件。登錄本網站即表示您同意這些條款，並聲明您有權力和能力簽訂這些條款。您應至少年滿 18 歲才能訪問本網站。如果您不同意這些條款的所有規定，請勿登錄和/或使用本網站。</p>
<p>這些條款要求以第 10.2 節中的個人仲裁來解決爭端，並在發生爭議時限制您可用的補救措施。</p>
<h2>訪問網站</h2>
<p><strong>受這些條款的約束。</strong> 我們授予您一個不可轉讓的、非排他性的、可撤銷的、有限的許可證，僅為您的個人、非商業用途訪問本網站。</p>
<p>我們保留更改、中止或終止網站的權利，無論是否提前通知您。您同意我們對您或任何第三方不承擔任何因網站或任何部分的更改、中斷或終止而產生的責任。</p>
<p><strong>無支持或維護。</strong> 您同意我們沒有義務為您提供與網站相關的任何支持。</p>
<p>除了您可能提供的任何用戶內容外，您了解網站及其內容中的所有知識產權，包括版權、專利、商標和商業機密，均屬於我們或我們的供應商。請注意，這些條款和對網站的訪問並不授予您任何知識產權的權利、所有權或利益，除了第 2.1 節中明確表示的有限訪問權利外。我們和供應商保留這些條款中未授予的所有權利。</p>`,
			section2: `<h2>訂閱條款</h2>
<p>歡迎使用我們的訂閱服務（以下稱為 "服務"）。此服務由香港 ADFLASH MEDIA LIMITED 提供（以下稱為 "我們" 或 "公司"）。通過使用或訂閱我們的服務，您同意遵守這些訂閱條款（以下稱為 "條款"）。</p>
<p>我們將為您提供基於訂閱的服務，包括專屬用戶配額。具體的服務內容和費用可能會隨時更改，並會通過電子郵件或我們的網站更新通知。</p>
<p>訂閱費用、支付方式和支付週期詳見定價頁面。逾期未付款的賬戶將暫停其付費服務。</p>
<p>所有訂閱費用不予退還，除非法律要求或按照我們的全權酌情決定。</p>
<p>您可以隨時取消訂閱。取消後，我們會繼續提供服務至當前訂閱期結束，但已支付的費用不予退還。</p>
<p>在以下情況下，我們也保留單方面終止或暫停您的服務的權利：</p>
<p>- 違反這些條款</p>
<p>- 未支付訂閱費用</p>
<p>我們將盡力提供高質量的服務，但我們不對服務的可用性、準確性或完整性做任何明示或暗示的保證。</p>`,
			section3: `<h2>其他用戶</h2>
<p><strong>其他用戶。</strong> 每個網站用戶對其自己的用戶內容負全責。由於我們不控制用戶內容，您承認並同意我們對任何用戶內容不負責，無論是由您還是他人提供的用戶內容。您同意我們不對因任何此類互動而產生的任何損失或損害負責。如果您與任何網站用戶之間發生糾紛，我們沒有義務介入。</p>
<p>您在此釋放並永遠解除我們及我們的高級職員、員工、代理、繼任者和受讓人因直接或間接源自於或直接或間接與網站相關的每一過去、現在和未來的爭議、索賠、爭議、需求、權利、義務、責任、行動和訴訟原因。如果您是加利福尼亞州居民，您在此根據以下內容放棄加利福尼亞民法典第 1542 條，其中規定：“一般性豁免不包括債權人在簽署豁免時不知道或懷疑存在的對其有利的索賠，這些索賠如果被其知悉，必須對其與債務人的和解產生重大影響。”</p>
<p><strong>Cookies 和網絡信標。</strong> 像任何其他網站一樣使用“Cookies”。這些 Cookies 用於存儲信息，包括訪客的偏好，以及訪客訪問或訪問的網站頁面。這些信息用於根據訪客的瀏覽器類型和/或其他信息來定制我們的網頁內容，以優化用戶體驗。</p>
<h2>免責聲明</h2>
<p>網站基於“按現狀”和“按可用”基礎提供，我們和我們的供應商明確否認任何及所有類型的保證和條件，無論是明示的、暗示的或法定的，包括所有的適銷性、適合特定用途、所有權、安靜享受、準確性或不侵犯的保證或條件。我們和我們的供應商不保證網站將滿足您的要求，將無中斷、及時、安全或無錯誤地可用，或將準確、可靠、無病毒或其他有害代碼、完整、合法或安全。如果適用法律要求對網站有任何保證，所有此類保證的期限均限制為自首次使用之日起九十（90）天。</p>
<p>某些司法管轄區不允許排除暗示保證，因此上述排除可能不適用於您。某些司法管轄區不允許限制暗示保證持續時間的限制，因此上述限制可能不適用於您。</p>`,
			section4: `<h2>責任限制</h2>
<p>在法律最大允許範圍內，我們或我們的供應商在任何情況下均不對您或任何第三方因這些條款或您使用或無法使用本網站而產生的任何利潤損失、數據丟失、替代產品的採購成本或任何間接的、後果性的、示範性的、偶然的、特殊的或懲罰性的損害承擔責任，即使我們已被告知有此類損害的可能性。訪問和使用本網站需由您自行決定和承擔風險，您將對因使用網站而對您的設備或計算機系統造成的任何損害或數據丟失負全責。</p>
<p>在法律最大允許範圍內，儘管本條款中有任何相反規定，我們對您因本協議而產生或與之相關的任何損害的責任，始終限制為最高五十美元（$50 美元）。多次索賠的存在不會擴大此限制。您同意我們的供應商不就本協議產生或與之相關的任何責任負責。</p>
<p>某些司法管轄區不允許排除或限制偶然或後果性損害的責任，因此上述限制或排除可能不適用於您。</p>
<p><strong>期限和終止。</strong> 根據本節的規定，當您使用本網站時，這些條款將始終有效。我們可自行決定隨時因任何原因暫停或終止您使用本網站的權利，包括您違反這些條款使用本網站的情況。您在本條款下的權利終止後，您的賬戶及訪問和使用本網站的權利將立即終止。您理解任何賬戶的終止可能涉及從我們的活動數據庫中刪除與您的賬戶相關的用戶內容。我們對您在本條款下的任何權利終止不承擔任何責任。即使您在本條款下的權利終止，這些條款中的以下規定仍將有效：第 2 節至第 2.5 節、第 3 節和第 4 節至第 10 節。</p>
<p></p>
<p>賠償。您將為我們、我們的附屬公司和我們的員工辯護，並使其免受因您使用服務（包括您的內容）、您在與服務相關的產品或服務中的開發或提供，以及您違反這些條款或適用法律而引起的任何索賠、損失和費用（包括律師費）。</p>
<p>免責聲明。服務以“按現狀”提供。除法律禁止的範圍外，我們及我們的附屬公司和許可方不對服務作出任何保證（明示的、暗示的、法定的或其他），並且否認所有保證，包括但不限於適銷性、適合特定用途、滿意的質量、不侵權、安靜享受的保證，以及任何在交易過程中或貿易使用中產生的保證。我們不保證服務將無中斷、準確或無錯誤，也不保證任何內容將是安全的、不丟失或不被修改。</p>`,
			section5: `<h2>版權政策。</h2>
<p>我們尊重他人的知識產權，並要求我們網站的用戶也這樣做。鑒於我們的網站，我們採納並實施了一項尊重版權法的政策，該政策規定移除任何侵權材料，並終止我們在線網站中屢次侵犯知識產權的用戶，包括版權。如果您認為我們的一位用戶正在通過使用我們的網站非法侵犯某個作品的版權，并希望移除所指稱的侵權材料，則必須以書面通知（根據 17 U.S.C. § 512(c)）的形式向我們指定的版權代理提供以下信息：</p>
<p>- 您的實體或電子簽名；</p>
<p>- 您聲稱被侵權的版權作品的識別信息；</p>
<p>- 您聲稱在我們服務中侵權並要求我們移除的材料的識別信息；</p>
<p>- 足夠的信息以便我們定位此類材料；</p>
<p>- 您的地址、電話號碼和電子郵件地址；</p>
<p>- 您有理由相信受異議材料的使用未經版權擁有者、其代理人或法律授權的聲明；以及</p>
<p>- 通知中的信息準確無誤的聲明，並在作偽證的情況下，您是所聲稱被侵權版權的擁有者或獲授權代表版權擁有者行事。</p>
<p>請注意，根據 17 U.S.C. § 512(f)，任何在書面通知中的重大事實錯誤陳述，將使投訴方自動承擔我們因書面通知和版權侵權指控而產生的任何損害、費用和律師費的責任。</p>`,
			section6: `<h2>一般條款</h2>
            <p>本條款可能會偶爾進行修訂，如果我們作出任何重大更改，我們可能會通過向您提供的最新的電子郵件地址發送電子郵件通知您，和/或在我們的網站上顯著刊登變更通知。您有責任向我們提供您最新的電子郵件地址。如果您提供給我們的最後一個電子郵件地址無效，我們寄發包含此通知的電子郵件仍將構成對通知中所描述變更的有效通知。對這些條款的任何更改將在我們向您發送電子郵件通知之後的三十（30）個日曆日或我們在網站上發布變更通知之後的三十（30）個日曆日內最早者生效。這些變更對新用戶將立即生效。繼續使用我們的網站即視為您對此類變更的確認以及同意受此類變更條款和條件的約束。爭議解決。請仔細閱讀本仲裁協議。它是您與我們之間合約的一部分，並影響您的權利。它包含強制性約束仲裁和集體訴訟豁免的程序。</p>
<p><strong>仲裁協議的適用性。</strong> 所有與條款相關或因使用我們所提供的任何產品或服務而產生的索賠和糾紛，若無法非正式地或在小額索賠法庭中解決，應根據本仲裁協議的條款以個人名義通過具約束力的仲裁解決。除非另有協議，所有仲裁程序應以英語進行。本仲裁協議適用於您與我們以及任何子公司、關聯公司、代理、員工、利益前任、繼任者和受讓人，以及在條款下提供服務或商品的所有授權或未授權用戶或受益人。</p>
<p><strong>通知要求及非正式糾紛解決。</strong> 在任一方尋求仲裁之前，必須先向對方發送描述索賠或糾紛性質和依據以及所要求補救的書面糾紛通知。向我們發送的通知應發送至我們的聯繫電子郵件。收到通知後，您和我們可以嘗試非正式地解決索賠或糾紛。如果在收到通知後三十（30）天內，您和我們未能解決索賠或糾紛，任一方可以開始仲裁程序。任何一方提出的任何和解提議金額在仲裁員確定任一方有權獲得的裁決金額之前不得透露給仲裁員。</p>
<p><strong>仲裁規則。</strong> 仲裁應通過美國仲裁協會（AAA）啟動，這是一家提供仲裁作為本節中規定的替代糾紛解決方法的機構。如果AAA無法進行仲裁，雙方應同意選擇另一家替代糾紛解決（ADR）提供者。ADR提供者的規則應管轄仲裁的所有方面，但在這些規則與條款衝突的情況下除外。可在線上adr.org查閱或通過致電1-800-778-7879聯繫AAA查閱AAA消費者仲裁規則。仲裁應由單一、中立的仲裁員進行。任何請求或糾紛中，請求人所要求的總獎金數額少於一萬美元（10,000.00美元）的，可以選擇通過具約束力的無出席仲裁來解決。對於請求或糾紛中所要求的總獎金數額為一萬美元（10,000.00美元）或更多的，聽證權將由仲裁規則決定。任何聽證會應在距您住所100英里範圍內的地點舉行，除非您居住在美國境外，並且雙方另有協議。如果您居住在美國境外，仲裁員應向雙方提供任何口頭聽證會日期、時間和地點的合理通知。仲裁員作出的任何裁決可在有管轄權的任何法院進行判決。如果仲裁員給您的裁決大於我們在啟動仲裁之前最後一次和解提議中的數額，我們將支付給您裁決金額或2,500.00美元中的較大者。每一方應承擔仲裁過程中產生的各自費用和支出，並支付ADR提供者的費用和費用的一半。</p>
<p><strong>無出席仲裁的額外規則。</strong> 如果選擇無出席仲裁，仲裁應通過電話、在線和/或僅基於書面提交進行；具體方式由啟動仲裁的一方選擇。除非雙方另有協議，否則仲裁不應涉及任何當事人或證人的個人出席。</p>
<p><strong>時間限制。</strong> 如果您或我們提起仲裁，仲裁行動必須在訴訟時效內和任何AAA規則對相關索賠的截止期限內啟動和/或要求。</p>
<p><strong>仲裁員的權限。</strong> 如果啟動仲裁，仲裁員將決定您和我們的權利和責任，並且糾紛不會與任何其他事項合併或與任何其他案件或當事人聯合。仲裁員有權針對全部或部分索賠作出決定。仲裁員有權授予金錢賠償，並授予適用法律、AAA規則和條款下個人可獲得的任何非金錢補救或救濟。仲裁員應發布書面裁決和決定聲明，描述裁決所依據的基本發現和結論。仲裁員具有與法院法官在個人基礎上授予救濟的相同權限。仲裁員的裁決是最終的，對您和我們具有約束力。</p>
<p><strong>放棄陪審團審判。</strong> 雙方特此放棄其憲法和法定權利，即放棄去法院並在法官或陪審團前進行審判的權利，而選擇根據本仲裁協議通過仲裁解決所有索賠和糾紛。仲裁程序通常比適用於法院的規則更有限、更高效且成本更低，並且僅受到法院的有限審查。在您與我們之間的任何州或聯邦法院中出現的任何訴訟中，包括為了撤銷或執行仲裁裁決或其他情況，您和我們均放棄所有陪審團審判的權利，而選擇由法官解決糾紛。</p>
<p><strong>放棄集體或合併行動。</strong> 在本仲裁協議範圍內的所有索賠和糾紛必須以個人名義而非集體名義進行仲裁或訴訟，並且多個客戶或用戶的索賠不能與任何其他客戶或用戶的索賠聯合或合併進行仲裁或訴訟。</p>
<p><strong>保密性。</strong> 仲裁程序的所有方面均應嚴格保密。雙方同意保密，除非法律另有要求。本段落不應阻止任何一方向法院提交為執行本協議、執行仲裁裁決或尋求禁令或衡平救濟所需的任何信息。</p>`,
			section7: `<p><strong>可分割性。</strong> 如果本仲裁協議的任何部分或部分被有管轄權的法院認定為無效或不可執行，則該特定部分或部分將無效且應被分割，其余部分協議將繼續有效。</p>
<p><strong>放棄權利。</strong> 索賠針對的任何一方可以放棄本仲裁協議中規定的任何或全部權利和限制。此類放棄不應放棄或影響本仲裁協議的任何其他部分。</p>
<p><strong>協議的存續性。</strong> 本仲裁協議將在您與我們的關係終止後繼續生效。</p>
<p><strong>小額索賠法庭。</strong> 儘管有上述規定，您或我們均可在小額索賠法庭提出個人訴訟。</p>
<p><strong>緊急衡平救濟。</strong> 儘管有上述規定，任何一方均可在州或聯邦法院尋求緊急衡平救濟，以維持仲裁期間的現狀。請求臨時措施不應被視為放棄本仲裁協議下的任何其他權利或義務。</p>
<p><strong>不受仲裁約束的索賠。</strong> 儘管有上述規定，誹謗、違反《計算機欺詐和濫用法》，以及侵犯或挪用對方的專利、版權、商標或商業機密的索賠不受本仲裁協議的約束。</p>
<p>在任何允許雙方在法院訴訟的情況下，雙方特此同意提交加利福尼亞州荷蘭縣法院的個人管轄權，以作此目的。</p>
<p>本網站可能受美國出口管制法律的約束，並可能受其他國家/地區的出口或進口法規的約束。您同意不直接或間接出口、再出口或轉讓從我們處獲得的任何美國技術數據或利用此類數據的任何產品，違反美國的出口法律或法規。</p>`,
			section8: `<p><strong>電子通訊。</strong> 您與我們之間的通訊採用電子方式，不論您是使用網站或向我們發送電子郵件，或我們在網站上發布通知或通過電子郵件與您通信。出於合同目的，您（a）同意以電子形式接收我們的通訊；並且（b）同意由我們以電子方式提供的所有條款和條件、協議、通知、披露和其他通訊滿足任何法律義務，這些義務如果以書面形式傳達也是有效的。</p>
<p><strong>完整條款。</strong> 這些條款構成您與我們之間關於使用本網站的完整協議。我們未能行使或執行這些條款中的任何權利或規定不應視為對該權利或規定的放棄。本條款中的部分標題僅為方便而設，並無法律或合同效力。“包括”一詞意為“包括但不限於”。如果這些條款的任何規定被認為無效或不可執行，這些條款的其他規定將不受影響，而無效或不可執行的規定將被視為已修改，使其在法律允許的最大範圍內有效和可執行。您與我們的關係是獨立承包商的關係，雙方均不是對方的代理或合作夥伴。未經我們事先書面同意，您不得轉讓、分包、委派或以其他方式轉讓這些條款以及您在此的權利和義務，任何違反上述規定的嘗試轉讓、分包、委派或轉讓均為無效。我們可以自由轉讓這些條款。這些條款中規定的條款和條件對受讓人具有約束力。</p>
<p><strong>您的隱私。</strong> 請閱讀我們的隱私政策。</p>
<p><strong>版權/商標信息。</strong> 版權©。保留所有權利。本網站上顯示的所有商標、標誌和服務標記均為我們或其他第三方的財產。未經我們或擁有該商標的第三方的事先書面同意，您不得使用這些商標。</p>
<h2>聯繫信息</h2>
<p>電子郵件: watch_support@adflash.cn</p>`
		},

		privacy: {
			section1: `<p>保護您的隱私對我們非常重要。我們希望以下聲明可以幫助您了解產品如何處理您通過互聯網偶爾向我們提供的個人識別信息。</p>
<p>基於我們的應用程序和相關服務，您可能會申請使用第三方提供的特殊功能服務。如果您需要註冊並使用任何特殊功能服務，請仔細閱讀該特殊功能服務的服務條款。通過接受或使用任何特殊功能服務，您確認您已閱讀並接受本協議及該特殊功能服務的服務條款，並同意受其約束。</p>
<p>有時我們需要信息來提供您請求的服務，本隱私聲明適用於其產品。</p>
<h2>團隊收集和接收的信息。</h2>
<p>當您使用軟件和服務時，我們可能會自動收集和存儲以下類型的信息：</p>
<p>1. 技術信息。關於您的設備以及您對軟件和服務以及安裝在您設備上的其他第三方軟件和應用程序（以下簡稱 “其他軟件”）的使用情況的某些技術信息，包括但不限於操作系統和其他軟件的類型、名稱和版本，包括其與軟件和服務的互動，ISP（互聯網服務提供商）、位置（GPS）、互聯網協議（IP）地址、匿名用戶標識符、設備配置和標識符（IDFA（iOS中用於廣告的標識符）、廣告ID（Android中用於廣告的標識符）等）、軟件和服務的版本，以及您的軟件和服務配置和設置；</p>
<p>2. 使用信息。關於您使用和與設備互動的某些使用相關信息，包括軟件和服務以及其他軟件。例如，您擁有的應用程序和您使用的應用程序以及您使用它們的頻率。您查看、使用和訪問的廣告，您如何使用它們以及您的反應；</p>
<p>3. 社交網絡。您通過軟件和服務在社交網絡上的互動；</p>`,
			section2: `<p>4. 註冊相關信息。您在註冊軟件和服務時提供的信息（如果適用），例如姓名、家庭或工作地址、電子郵件地址、電話和傳真號碼、出生日期和性別；</p>
<p>5. 支持相關信息。如果您通過我們的支持部分或電子郵件與我們通信，我們也可能會收集您的電子郵件地址、姓名和您自願提交的任何支持相關信息。當您使用軟件和服務時，我們會自動生成一個匿名的內部用戶ID，該ID被分配給您使用軟件和服務（以下簡稱 “用戶ID”），並可能與上述任何信息結合。</p>
<p>6. 設備信息</p>
<p>我們收集有關您設備的信息，包括硬件型號、操作系統及版本、唯一設備標識符（包括SSaid、GAID）、瀏覽器類型和語言、移動設備電話號碼、移動網絡信息、國際移動設備識別碼（“IMEI”）、廣告標識符（“IDFA”）、供應商標識符（“IDFV”）、集成電路卡標識符（“ICCID”）、媒體訪問控制（“MAC”）地址、型號和分辨率，這些信息將被我們用於計算使用我們產品和服務的設備數量，並分析設備型號和圖形適應方面的數據。您可以選擇不提供某些信息，但這樣您可能無法利用我們許多的應用程序。我們還會收集某些設備信息，以便在您使用我們的服務時遇到崩潰或其他問題（希望很少發生）時幫助我們診斷問題。</p>
<p>7. 相機和照片</p>
<p>我們許多的服務需要我們從您的設備相機和照片中收集圖像和其他信息。我們僅會在您同意後訪問您的相機和照片。您可以選擇不提供此信息，但這樣您可能無法利用我們許多的服務。</p>
<p>8. 本地存儲</p>
<p>我們可能會通過瀏覽器網絡存儲（包括HTML 5）和應用程序數據緩存等機制在您的設備上本地收集和存儲信息。我們存儲的信息不會與任何第三方分享。</p>
<p>9. 非個人信息</p>`,
			section3: `<p>非個人信息是指不能直接與任何特定個人相關聯的數據，例如您的Android ID、CPN型號、內存大小、您的手機IMEI號碼、手機型號、ROM、已安裝應用程序名稱和包名、手機運營商、位置、安裝、卸載、使用頻率等。</p>
<p>我們可能在以下情況下收集和使用非個人信息。為了更好地理解用戶行為、解決產品和服務中的問題、改進我們的產品、服務和廣告，我們可能會收集非個人信息，如已安裝應用程序的名稱和包名、安裝數據、使用頻率、國家、設備和渠道。</p>
<h2>團隊如何使用您的信息？</h2>
<p>團隊可能以以下方式將收集的信息用於分析、商業和運營目的：</p>
<p>1. 運營和更新軟件和服務，並為您提供現有和新的功能和特點；</p>
<p>2. 改進和定製軟件和服務；</p>
<p>3. 為您提供個性化內容，包括通過我們或我們的合作夥伴提供的針對性興趣廣告；</p>
<p>4. 維持和改進軟件和服務的質量和運營；</p>
<p>5. 通知您軟件和服務的更新；</p>
<p>6. 為您提供支持並處理您的投訴和/或反饋；</p>
<p>7. 在涉及您與軟件和服務相關的糾紛中採取任何行動；</p>`,
			section4: `<p>8. 執行適用於軟件和服務的條款和條件，並防止非法活動和濫用行為；</p>
<p>9. 遵守並滿足任何適用的法律法規、法律程序或可執行的政府要求。與第三方共享信息。</p>
<h2>兒童隱私</h2>
<p>這些服務不針對13歲以下的任何人。我不會故意收集13歲以下兒童的個人識別信息。如果我發現13歲以下的兒童向我提供了個人信息，我會立即從我們的服務器中刪除此信息。如果您是父母或監護人，並且您知道您的孩子向我們提供了個人信息，請與我聯繫，以便我能採取必要的行動。</p>
<h2>隱私政策變更</h2>
<p>我可能會不時更新我們的隱私政策。因此，建議您定期查看此頁面以了解任何變更。我將通過在此頁面上發布新的隱私政策來通知您任何變更。這些變更在發布後立即生效。</p>
<h2>聯繫我們</h2>
<p>如果您對我的隱私政策有任何問題或建議，請隨時通過以下方式聯繫我。電子郵件: watch_support@adflash.cn</p>`,
		},
		refund: {
			section1: `<p>感謝您的購買。如果您對購買不完全滿意，我們願意為您提供幫助。</p>
<p>要開始退款過程，請按照以下步驟操作。</p>
<p>訂閱產品（例如基本訂閱）</p>
<p>如果您停止使用但忘記取消訂閱，只要您在付款後未使用任何會話或創建任何圖像，我們通常很樂意退款給您最近的訂閱付款。</p>
<p>要請求退款，請通過您的帳戶和反饋表向我們發送退款請求。我們可能需要向您詢問特定信息以幫助我們確認您的身份。</p>
<p>數字產品（例如時間包，訂閱）</p>
<p>如果已使用任何時間包和訂閱，則不會進行退款。我們不提供部分或按比例退款。</p>
<p>您有14個日曆天的時間從交易日期起請求退款。</p>
<p>一旦我們收到您的退款請求，我們的工作人員將檢查您的會員狀態以確定您是否有資格獲得退款。</p>`,
			section2: `<p>如果您獲得批准，您的退款將被處理。退款將記入用於購買的原[付款方式]。</p>
<p>延遲或缺少退款</p>
<p>如果您尚未收到退款，請首先再次檢查您的銀行賬戶。</p>
<p>然後聯繫您的銀行卡公司，退款的正式釋放可能需要一些時間。</p>
<p>下一步聯繫您的銀行。通常在退款釋放之前需要一些處理時間。</p>
<p>如果您已完成所有這些操作但仍未收到退款，請通過watch_support@adflash.cn聯繫我們。</p>
<p>促銷商品</p>
<p>只有正價商品可以退款，遺憾的是促銷商品不能退款。</p>`,
		},
		us: {
			section1: `<p>公司信息</p>
<p>名稱：HONG KONG ADFLASH MEDIA LIMITED</p>
<p>地址：香港干諾道中139號新芳大廈11樓1101室</p>
<p>電子郵件：watch_support@adflash.cn</p>`,
		},
	}
}