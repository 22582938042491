import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/fonts/font.css";
import "./assets/icons";
import Vant from "vant";
import "vant/lib/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import { Base64 } from 'js-base64';
import clipboard from 'clipboard';
import i18n from './i18n'
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole()
// Vue.use(vConsole)
Vue.use(Vant);
Vue.use(ElementUI);

Vue.config.productionTip = false;
// Vue.prototype.$Base64 = Base64;
Vue.prototype.clipboard = clipboard;  //注册到vue原型上

   // 初始化store状态
   if (localStorage.getItem('fontSetting')) {
    store.commit('setFontSize', localStorage.getItem('fontSetting'));
  }
  if (localStorage.getItem('OpenSound')) {
    store.commit('setOpenSound', localStorage.getItem('OpenSound'));
  }
  if (localStorage.getItem('isInit')) {
    store.commit('setInit', localStorage.getItem('isInit'));
  }
  if (localStorage.getItem('myCreation')) {
    store.commit('setMyCreation',JSON.parse(localStorage.getItem('myCreation')));
  }

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
