<template>
  <div id="app">
        <PageSlide indexPagePath="/">
        <div>
          <div v-if="!noNetWork" :class="[!noNetWork?'scale-up-center':'','netBox']" >
            <netWorkTip/>
          </div>
          
          <router-view />
        </div>
    
    </PageSlide>
    
  </div>
</template>

<script>
import { Encrypt, Decrypt } from "@/utils/encryp.js";
import PageSlide from '@/components/PageSlide/PageSlide.vue'
import axios from 'axios';
import {langChange} from '@/utils/language';
import netWorkTip from '@/components/netWorkTip/netWorkTip.vue';
export default {
  name: "App",
  data(){
    return{
      noNetWork:true
    }
  },
    components: {
    PageSlide,
    netWorkTip
  },
      computed: {
        getPP() {
            return this.$store.getters.getPP;
        },
        getPOs() {
            return this.$store.getters.getPOs;
        },
        getPOsVersion() {
            return this.$store.getters.getPOsVersion;
        },
        getPDeviceId() {
            return this.$store.getters.getPDeviceId;
        },
        getPAppPkgName() {
            return this.$store.getters.getPAppPkgName;
        },
        getPAppVersion() {
            return this.$store.getters.getPAppVersion;
        },
        getPSdkVersion() {
            return this.$store.getters.getPSdkVersion;
        },
        getPChannelId() {
            return this.$store.getters.getPChannelId;
        },
        getPSdkChannelId() {
            return this.$store.getters.getPSdkChannelId;
        },
        getPToken() {
            return this.$store.getters.getPToken;
        },
        getPBind() {
            return this.$store.getters.getPBind;
        },
        getPNotificationId() {
            return this.$store.getters.getPNotificationId;
        },
        getPNotificationType() {
            return this.$store.getters.getPNotificationType;
        },
        getPLanguage() {
            return this.$store.getters.getPLanguage;
        },
        getPWatch() {
            return this.$store.getters.getPWatch;
        },
        getPWatchId() {
            return this.$store.getters.getPWatchId;
        },
        getLanguage() {
            return this.$store.getters.getLanguage;
        },
        getParams() {
            return this.$store.getters.getParams;
        },
    },
    created() {
       // 默认语言
        if(this.getLanguage != "" && this.getLanguage != null) {
            this.$i18n.locale = langChange(this.getLanguage);
        }
        if(localStorage.getItem("i18nLanguage") != null && localStorage.getItem("i18nLanguage") != '') {
            this.$i18n.locale = localStorage.getItem("i18nLanguage");
            // console.log(localStorage.getItem("i18nLanguage"));
            console.log(111)
        }
    },
    mounted() {
       window.addEventListener('offline', ()=>{
   // 网络由正常到异常时才会触发
   console.log("网络由正常到异常时才会触发");
  this.noNetWork = false;
  });
  window.addEventListener('online',()=>{
   console.log("网络正常");
    this.noNetWork = true;
  });
    },
   beforeUpdate() {
        this.encryp();
        // console.log("updated--------------------");
        // this.test();
         window.removeEventListener('offline', ()=>{});
        window.removeEventListener('online', ()=>{});
    },
    methods:{
      encryp() {
            if (this.$route.query[this.getPP] != null) {
                const str = this.$route.query[this.getPP].replace(/ /g, "+").replace(/\n/g, '').replace(/\r/g,"");
                console.log(str);
                // console.log(Decrypt(str));
                var pr = JSON.parse(Decrypt(str));
                console.log(pr);
                this.$store.commit("SET_OS", pr[this.getPOs]);
                this.$store.commit("SET_OS_VERSION", pr[this.getPOsVersion]);
                this.$store.commit("SET_DEVICE_ID", pr[this.getPDeviceId]);
                this.$store.commit("SET_APP_PKG_NAME", pr[this.getPAppPkgName]);
                this.$store.commit("SET_APP_VERSION", pr[this.getPAppVersion]);
                this.$store.commit("SET_SDK_VERSION", pr[this.getPSdkVersion]);
                this.$store.commit("SET_CHANNEL_ID", pr[this.getPChannelId]);
                this.$store.commit("SET_SDK_CHANNEL_ID", pr[this.getPSdkChannelId]);
                this.$store.commit("SET_TOKEN", pr[this.getPToken]);
                this.$store.commit("SET_BIND", pr[this.getPBind]);
                this.$store.commit("SET_NOTIFICATION_ID",pr[this.getPNotificationId]);
                this.$store.commit("SET_NOTIFICATION_TYPE",pr[this.getPNotificationType]);
                this.$store.commit("SET_LANGUAGE",  pr[this.getPLanguage]);
                if(localStorage.getItem("i18nLanguage") != null && localStorage.getItem("i18nLanguage") != '') {
            this.$i18n.locale = localStorage.getItem("i18nLanguage");
        }else{
           this.$i18n.locale = langChange(pr[this.getPLanguage])
        }
               
                            console.log(222)

                // 新增 watch 和 watchid
                this.$store.commit("SET_WATCH_ID", pr[this.getPWatchId])
                let watchList = []
                for (let i = 0; i < pr[this.getPWatch].length; i++) {
                    const item = pr[this.getPWatch][i];
                    // console.log(item);
                    const watchItem = {
                        watchId: item[this.getPWatchId],
                        bind: item[this.getPBind],
                    };
                    watchList.push(watchItem);
                }
                this.$store.commit("SET_WATCH", watchList);
              //  this.init();
            }
        },
        init(){
          axios.post("http://44.236.239.108:9091/prod-api/aismart/text/json/upload",{
            name:'json',
            json:this.$route.query[this.getPP]+'--'+JSON.stringify(this.$store.getters.getParams)
          }).then(res=>{

          })
        }
    }
}
</script>

<style lang="scss">
	
body {
  height: 100%;
  width: 100%;
//   overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: "SFUIDisplay-Regular";
//   overflow: hidden;
  font-size: 4vw;
  color: #08080d;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none; /* 去掉下划线 */
  color: inherit; /* 继承父元素的颜色 */
}

a:hover {
  text-decoration: none; /* 确保悬停时也不会出现下划线 */
}
.noWrap {
  white-space: nowrap !important;
}
.netBox{
width: 97vw;
    height: 19vw;
    background-color: rgb(252, 206, 213);
    border-radius: 3vw;
    position: fixed;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    top:12vw;
    margin-left: 1.5vw;
}
.scale-up-center {
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
    
  }
  100% {
    transform: scale(1);
  }
}

</style>
<style>
@import "./assets/fonts/font.css";
p,div {
    font-family: "SFUIDisplay-Regular";
}
</style>
